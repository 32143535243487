import { Directive, Attribute } from '@angular/core';
import { AsyncValidator, AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, of, timer } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import { PortalService } from '../_services/index';

export function externalEmailValidator(_portal: PortalService): AsyncValidatorFn {
  return (control:AbstractControl): Promise<ValidationErrors|null> | Observable<ValidationErrors|null> => {
    if ( control.value == null || control.value == undefined || control.value == "" ) {
      return of(null).pipe();
    }
    return timer(500).switchMap(() => {
      return _portal.validateEmail(control.value).map(
        response => {
          console.log("The externalEmailvalidation returned %o", response);
          let str=response.text().replace(/<br\/>/g,'\n');
          console.log("externalEmailValidator returning '" + str + "'");
          return str == "" ? null : { 'externalvalid' : str };
        }
      )
    })
  }
}

@Directive({
  selector: '[externalEmail]',
  providers: [
    {provide: NG_ASYNC_VALIDATORS, useExisting: ExternalEmailValidator, multi: true}
  ]
})

export class ExternalEmailValidator implements AsyncValidator {
  constructor ( @Attribute('custom_email') public email: string,
                private _portalService: PortalService) {}

  validate(c: AbstractControl): Promise<ValidationErrors|null> | Observable<ValidationErrors|null> {
    return externalEmailValidator(this._portalService)(c);
  }
}
