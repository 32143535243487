import { OnInit, Renderer2, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, PortalService } from '../_services/index';
import { CurrencyPipe } from '@angular/common';
import { Countries } from '../_helpers/index';
import { HttpClient, HttpEventType } from "@angular/common/http";
import { environment } from '../../environments/environment';
var OrderFormComponent = /** @class */ (function () {
    function OrderFormComponent(ngZone, renderer2, router, portalService, currencyPipe, alertService, http) {
        this.ngZone = ngZone;
        this.renderer2 = renderer2;
        this.router = router;
        this.portalService = portalService;
        this.currencyPipe = currencyPipe;
        this.alertService = alertService;
        this.http = http;
        /*
        orderForm = new FormGroup({
          order_date: new FormControl('')
        })
      */
        this.loading = true;
        this.model = {};
        this.countries = {};
        this.versions = {};
        this.licenses = {};
        this.currentUser = {};
        this.loading = true;
        this.gatewayUrl = environment.gatewayUrl;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var msie = /msie\s|trident\//i.test(window.navigator.userAgent);
        if (msie) {
            this.router.navigate(["/unsupported"]);
        }
        this.countries = Countries.getCountries();
        this.versions['default'] = [
            { id: '', name: ' - Choose One - ', selected: true, disabled: true },
            { id: 'GGW6.3', name: 'GO-Global v6.3' },
            { id: 'GGW6.2', name: 'GO-Global v6.2' },
            { id: 'GGW6.1', name: 'GO-Global v6.1' },
            { id: 'GGW6.0', name: 'GO-Global v6.0' },
            { id: 'GGW5.0', name: 'GO-Global v5.0' },
            { id: 'GGW4.8', name: 'GO-Global v4.8' },
            /*
              {id: 'SSL60W', name: 'GO-Global v6.0 Encryption'},
              {id: 'SSL50W', name: 'GO-Global v5.0 Encryption'},
              {id: 'SSL48W', name: 'GO-Global v4.8 Encryption'},
            */
            { id: 'GGX2.4', name: 'GO-Global for UNIX v2.4' },
            { id: 'GGX2.3', name: 'GO-Global for UNIX v2.3' },
            /*
              {id: 'GGX2.2', name: 'GO-Global for UNIX v2.2'},
              {id: 'PROFSVC', name: 'Professional Services'},
            */
            { id: 'OTHER', name: 'Other - Note in Special Instructions' }
        ];
        this.versions['cloud'] = [
            { id: '', name: ' - Choose One - ', selected: true, disabled: true },
            { id: 'GGW6.3', name: 'GO-Global v6.3' },
            { id: 'GGW6.2', name: 'GO-Global v6.2' },
        ];
        this.versions['onprem'] = [
            { id: '', name: ' - Choose One - ', selected: true, disabled: true },
            { id: 'GGW6.3', name: 'GO-Global v6.3' },
            { id: 'GGW6.2', name: 'GO-Global v6.2' },
            { id: 'GGW6.1', name: 'GO-Global v6.1' },
            { id: 'GGW6.0', name: 'GO-Global v6.0' },
            { id: 'GGW5.0', name: 'GO-Global v5.0' },
        ];
        this.licenses = [
            { id: 'LIC-0', name: 'LIC-0', description: 'This list of licenses is fake.' },
            { id: 'LIC-1', name: 'LIC-1', description: 'Tom Castanzo\'s Subscription License' },
            { id: 'LIC-2', name: 'LIC-2', description: 'Troy\'s Old Expired License' }
        ];
    }
    OrderFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        var today = new Date();
        this.model.order_date = { 'year': today.getFullYear(),
            'month': today.getMonth() + 1,
            'day': today.getDate()
        };
        this.model.validated = {};
        this.model.notfound = {};
        this.model.found = {};
        this.model.duplicateContact = {};
        this.model.mode = 'unknown';
        this.model.payment_method = '';
        this.model.cc_type = '';
        this.model.maint_qty = 1;
        this.model.partner_type = 'reseller';
        this.model.sub_type = '';
        this.model.sub_dur = '';
        this.model.sub_tenancy = '';
        this.model.sub_origin = '';
        this.model.sub_addon_license = {};
        this.model.sub_addon_license.siteadmin1 = '';
        this.model.sub_addon_license.siteadmin2 = '';
        this.model.sub_addon_license.siteadmin3 = '';
        this.model.sub_existing_trial_license = { 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': '' };
        this.model.sub_current_qty = 0;
        this.model.sub_qty = '';
        this.model.ver1 = "";
        this.model.show_readme = true;
        this.model.debug = false;
        this.model.files = [];
        this.model.enabled_modes = '';
        this.loading = true;
        this.portalService.getUserAccountInfo()
            .subscribe(function (data) {
            _this.loading = false;
            console.log(" data = %o", data);
            _this.model.user = data.user;
            _this.model.billto_contact_name = data.user.firstname + " " + data.user.lastname;
            _this.model.billto_contact_email = data.user.email;
            _this.model.billto_phone = data.user.phone;
            _this.model.billto_company = data.user.account.name;
            /*
            if ( data.user.account.city != '' || data.user.account.state != '' || data.user.account.postalCode != '' ) {
              data.user.account.streetaddress += "\n" + [[data.user.account.city, data.user.account.state].join(', '),
                data.user.account.postalCode].join(' ');
            }
            */
            var splits = data.user.account.streetaddress.split('\n');
            switch (splits) {
                default:
                case 4:
                    _this.model.billto_address4 = splits[3];
                case 3:
                    _this.model.billto_address3 = splits[2];
                case 2:
                    _this.model.billto_address2 = splits[1];
                case 1:
                    _this.model.billto_address = splits[0];
            }
            /*
            if ( this.countries.find((c: any) => c.name == data.user.account.country) == undefined) {
              this.countries.unshift({id: data.user.account.country, name: data.user.account.country});
            }
            */
            _this.model.billto_country = data.user.account.country;
            _this.model.billto_email = data.user.email;
            /* Enable modes of the form based on values in the Pricing Level */
            var matches = data.user.account.pricingLevel.match(/\[(.*?)\]/);
            if (matches) {
                _this.model.enabled_modes = matches[1];
            }
            if (data.user.account.type == "Admin") {
                _this.model.enabled_modes = 'SPC';
            }
            console.log("enabled order modes = " + _this.model.enabled_modes);
        }, function (error) {
            _this.loading = false;
            _this.alertService.error("Error getting user information: " + error + ". Please login again.");
            _this.router.navigate(["/orderform"]);
        });
    };
    OrderFormComponent.prototype.toggleReadme = function () {
        this.model.show_readme = (this.model.show_readme == true) ? false : true;
    };
    OrderFormComponent.prototype.isNotANumber = function (num) {
        return Number.isNaN(Number(num));
    };
    OrderFormComponent.prototype.unformat = function (amount) {
        //console.log(`unformat '${amount}'`);
        if (amount == undefined || amount == null || amount == '') {
            return 0;
        }
        amount = amount.replace(/\$/g, '').replace(/,/g, '');
        return parseFloat(amount);
    };
    OrderFormComponent.prototype.untransformAmount = function (event) {
        event.target.value = this.unformat(event.target.value);
        event.target.select();
    };
    OrderFormComponent.prototype.transformAmount = function (event) {
        event.target.value = this.currencyPipe.transform(event.target.value, '$');
    };
    OrderFormComponent.prototype.setFocus = function (selector) {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            setTimeout(function () {
                console.log("focusing on: %o", selector);
                try {
                    _this.renderer2.selectRootElement(selector).focus();
                }
                catch (error) {
                    console.log("trying to focus on %o: %s", selector, error);
                    setTimeout(function () {
                        _this.setFocus(selector);
                    }, 50);
                }
            }, 0);
        });
    };
    OrderFormComponent.prototype.extended = function (qty, unitprice) {
        return this.currencyPipe.transform(qty * unitprice, '$');
    };
    Object.defineProperty(OrderFormComponent.prototype, "total", {
        get: function () {
            var total = 0;
            switch (this.model.mode) {
                case 'standard':
                    if (this.model.hasOwnProperty('qty1') && this.model.hasOwnProperty('unit1')) {
                        total += this.model.qty1 * this.unformat(this.model.unit1);
                    }
                    if (this.model.hasOwnProperty('qty2') && this.model.hasOwnProperty('unit2')) {
                        total += this.model.qty2 * this.unformat(this.model.unit2);
                    }
                    break;
                case 'subscription':
                    if (this.model.hasOwnProperty('sub_qty') && this.model.hasOwnProperty('sub_units')) {
                        //total += this.model.sub_qty * this.unformat(this.model.sub_units);
                        if (this.model.sub_cloud_desc && this.model.sub_cloud_desc.includes('usage')) {
                            this.model.total = "N/A";
                        }
                        else {
                            if (this.model.sub_type == 'addon') {
                                total += this.extPrice * this.addonLicenseMultiplier();
                            }
                            else {
                                total += this.extPrice;
                            }
                        }
                    }
                    break;
                case 'maint_renew':
                    if (this.model.hasOwnProperty('maint_unit')) {
                        total += this.model.maint_unit;
                    }
                    break;
            }
            console.log("total=%o", total);
            if (Number.isNaN(Number(total)) == true) {
                return "$0.00";
            }
            this.model.total = this.currencyPipe.transform(total, '$');
            return this.model.total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderFormComponent.prototype, "numericTotal", {
        get: function () {
            return this.unformat(this.total);
        },
        enumerable: true,
        configurable: true
    });
    OrderFormComponent.prototype.onSubCloudDescChange = function (event) {
        this.model.sub_units = '$0.00';
        this.model.sub_total = 0;
        if (this.model.sub_cloud_desc.includes('usage')) {
            this.model.total = 'N/A';
        }
        else {
            this.model.total = 0;
        }
    };
    OrderFormComponent.prototype.onSubOnPremDescChange = function (event) {
        this.model.sub_units = '$0.00';
        this.model.sub_total = 0;
    };
    OrderFormComponent.prototype.checkContactsForDupes = function () {
        var _this = this;
        var emailFields = document.querySelectorAll("input[email]");
        this.model.duplicateContact = {};
        console.log("checking for duplicate emails");
        Array.from(emailFields).forEach(function (element) {
            Array.from(emailFields).forEach(function (element2) {
                if (element.name != element2.name && element.value != ""
                    && _this.model.duplicateContact[element.name] != true) {
                    console.log("checking " + element.name + " vs. " + element2.name);
                    if (element.value == element2.value) {
                        console.log(">> found duplicate: " + element.value + " == " + element2.value);
                        if (_this.model[element.name + "_firstname"] != undefined) {
                            _this.model.duplicateContact[element2.name] = true;
                            _this.model.duplicateContact[element2.name + "_firstname"] = _this.model[element.name + "_firstname"];
                            _this.model.duplicateContact[element2.name + "_lastname"] = _this.model[element.name + "_lastname"];
                        }
                        else if (_this.model[element2.name + "_firstname"] != undefined) {
                            _this.model.duplicateContact[element.name] = true;
                            _this.model.duplicateContact[element.name + "_firstname"] = _this.model[element2.name + "_firstname"];
                            _this.model.duplicateContact[element.name + "_lastname"] = _this.model[element2.name + "_lastname"];
                        }
                        else {
                            console.log("unable to determine which is the 'real' field");
                        }
                    }
                }
            });
        });
    };
    OrderFormComponent.prototype.checkContact = function (event) {
        var _this = this;
        var controlName = event.target.name;
        console.log("checkContact target: %o", event.target);
        if (!event.target.classList.contains('ng-invalid')
            && event.target.value != "" && event.target.value != this.model.validated[controlName]) {
            this.loading = true;
            this.portalService.verifyEmail(event.target.value)
                .subscribe(function (data) {
                _this.loading = false;
                console.log("verify email: %o", data);
                if (data.user._found == 'true') {
                    _this.model.validated[controlName] = event.target.value;
                    _this.model.notfound[controlName] = false;
                    _this.model.found[controlName] = data.user;
                }
                else {
                    delete _this.model.found[controlName];
                    _this.model.notfound[controlName] = true;
                    _this.setFocus("#" + controlName + "_firstname");
                }
            }, function (error) {
                _this.loading = false;
                _this.alertService.error('Unknown error : ' + error + '.');
                console.log("error: %o", error);
            });
        }
        else if (event.target.value == this.model.validated[controlName]) {
            this.model.notfound[controlName] = false;
        }
        this.checkContactsForDupes();
    };
    OrderFormComponent.prototype.updateContact = function (event) {
        var controlName = event.target.name;
        if (event.target.value != this.model.validated[controlName]) {
            this.model.notfound[controlName] = false;
            delete this.model.found[controlName];
            delete this.model.validated[controlName];
            delete this.model[controlName + "_firstname"];
            delete this.model[controlName + "_lastname"];
        }
    };
    OrderFormComponent.prototype.resetExistingLicense = function () {
        // called when the existing trial license field is modified
        this.model.sub_existing_trial_license = { 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': '' };
    };
    OrderFormComponent.prototype.resetSubscriptionType = function () {
        this.model.sub_dur = '';
        this.model.sub_tenancy = '';
        this.model.sub_existing_license = '';
        this.model.sub_existing_siteadmin = '';
        this.model.sub_existing_trial_license = { 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': '' };
        this.model.sub_existing_trial_license_error = '';
        this.model.sub_addon_existing = '';
        this.model.sub_addon_siteadmin = '';
        this.model.sub_addon_license = { 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': '' };
        this.model.siteadmin1 = '';
        this.model.siteadmin2 = '';
        this.model.siteadmin3 = '';
        delete this.model.found.siteadmin1;
        delete this.model.found.siteadmin2;
        delete this.model.found.siteadmin3;
        this.model.sub_qty = '';
    };
    OrderFormComponent.prototype.onTenancyChange = function () {
        if (this.model.sub_tenancy != 'onprem') {
            this.model.sub_cloud_desc = '';
        }
        if (this.model.sub_tenancy != 'cloud') {
            this.model.sub_onprem_desc = '';
        }
        this.model.sub_ver = '';
        this.model.sub_existing_license = '';
        this.model.sub_existing_siteadmin = '';
        this.model.sub_existing_trial_license = { 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': '' };
        this.model.sub_existing_trial_license_error = '';
        this.model.sub_addon_existing = '';
        this.model.sub_addon_siteadmin = '';
        this.model.sub_addon_license = { 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': '' };
    };
    OrderFormComponent.prototype.onOriginChange = function () {
        this.model.sub_existing_license = '';
        this.model.sub_existing_siteadmin = '';
        this.model.sub_existing_trial_license = { 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': '' };
        this.model.sub_existing_trial_license_error = '';
        this.model.sub_addon_existing = '';
        this.model.sub_addon_siteadmin = '';
        this.model.sub_addon_license = { 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': '' };
    };
    OrderFormComponent.prototype.toggleDebug = function () {
        this.model.debug = (this.model.debug ? false : true);
        console.log("debug is " + (this.model.debug ? "true" : "false"));
    };
    OrderFormComponent.prototype.lookupExistingTrial = function () {
        var _this = this;
        // get the specified trial license and display relevant bits for Confirmation
        if (this.model.sub_existing_license != undefined
            && this.model.sub_existing_license != ''
            && this.model.sub_existing_siteadmin != undefined
            && this.model.sub_existing_siteadmin != '') {
            if (!this.model.sub_existing_license.includes('LIC-')) {
                this.model.sub_existing_license = 'LIC-' + this.model.sub_existing_license;
            }
            this.loading = true;
            this.portalService.lookupExistingTrial(this.model.sub_existing_license, this.model.sub_existing_siteadmin)
                .subscribe(function (data) {
                _this.loading = false;
                console.log("lookupExistingTrial: %o", data);
                if (data.failure) {
                    _this.model.sub_existing_trial_license = {};
                    _this.model.sub_existing_trial_license_error = data.failure;
                }
                else {
                    delete _this.model.sub_existing_trial_license_error;
                    _this.model.sub_existing_trial_license = data.license;
                    _this.model.sub_qty = 1;
                    _this.model.sub_desc = '';
                }
            }, function (error) {
                _this.loading = false;
                _this.alertService.error('Unknown error: ' + error + '.');
                console.log("error: %o", error);
            });
        }
        else {
            this.model.sub_existing_trial_license = {};
        }
    };
    OrderFormComponent.prototype.lookupExistingLicense = function () {
        var _this = this;
        // get the specified existing cloud licenses to add seats to it
        if (this.model.sub_addon_existing != undefined
            && this.model.sub_addon_existing != ''
            && this.model.sub_addon_siteadmin != undefined
            && this.model.sub_addon_siteadmin != '') {
            if (!this.model.sub_addon_existing.includes('LIC-')) {
                this.model.sub_addon_existing = 'LIC-' + this.model.sub_addon_existing;
            }
            this.loading = true;
            this.portalService.lookupExistingLicense(this.model.sub_addon_existing, this.model.sub_addon_siteadmin)
                .subscribe(function (data) {
                _this.loading = false;
                console.log("lookupExistingLicense: %o", data);
                if (data.failure) {
                    _this.model.sub_addon_license = {};
                    _this.model.sub_addon_license_error = data.failure;
                }
                else {
                    delete _this.model.sub_addon_license_error;
                    _this.model.sub_addon_license = data.license;
                    _this.model.sub_current_qty = data.license.seats;
                    _this.model.sub_qty = 0;
                    _this.model.sub_desc = data.license.profile.name;
                    _this.model.sub_ver = data.license.version;
                    console.log("existing license: %o", data.license);
                    // figure out the number of months left in this licenses
                    var exp_date = new Date(data.license.expiration);
                    var today = new Date(Date.now());
                    var months_left = 1 +
                        exp_date.getMonth() - today.getMonth() +
                        (12 * (exp_date.getFullYear() - today.getFullYear()));
                    if (months_left < 0) {
                        months_left = 0;
                    }
                    _this.model.sub_addon_license.months_left = months_left;
                }
            }, function (error) {
                _this.loading = false;
                _this.alertService.error('Unknown error: ' + error + '.');
                console.log("error: %o", error);
            });
        }
        else {
            this.model.sub_addon_license = {};
        }
    };
    Object.defineProperty(OrderFormComponent.prototype, "extPrice", {
        get: function () {
            var multiplier = 1;
            if (this.model.sub_tenancy == 'cloud') {
                if (!this.model.sub_cloud_desc.includes('usage'))
                    switch (this.model.sub_cloud_desc) {
                        case '3month':
                        case '3monthsso':
                            multiplier = 3;
                    }
            }
            else if (this.model.sub_tenancy == 'onprem') {
                switch (this.model.sub_onprem_desc) {
                    case '2month':
                        multiplier = 2;
                        break;
                    case ' 3monthsso':
                    case '3month':
                        multiplier = 3;
                        break;
                }
            }
            else if (this.model.sub_addon_license.profile != undefined) {
                return this.addonPrice;
            }
            var val = this.model.sub_qty * this.model.sub_units * multiplier;
            if (isNaN(val))
                val = 0;
            console.log('compute extPrice qty ' + this.model.sub_qty + ' unit ' + this.model.sub_units + ' = val ' + val);
            this.model.sub_ext_field = '$' + val.toFixed(2);
            if (this.model.mode == 'subscription') {
                this.model.total = this.model.sub_ext_field;
            }
            return val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderFormComponent.prototype, "addonPrice", {
        get: function () {
            var num = 0;
            if (this.model.sub_addon_license.profile != undefined) {
                if (this.parseTerm(this.model.sub_addon_license.profile.termLength) == true) {
                    num = this.model.sub_addon_license.months_left * this.model.sub_qty * (this.model.sub_units / 12);
                }
                else {
                    var multiplier = this.model.sub_addon_license.months_left;
                    console.log("addonPrice: multiplier = " + multiplier + ", qty=" + this.model.sub_qty + ", unitprice=" + this.model.sub_units);
                    num = Number(multiplier * this.model.sub_qty * this.model.sub_units);
                }
                if (isNaN(num)) {
                    num = 0;
                }
                if (num < 0) {
                    num = 0;
                }
                this.model.sub_ext_field = '$' + num.toFixed(2);
                //console.log('addonPrice set sub-ext_field to ' + this.model.sub_ext_field);
                this.model.total = this.model.sub_ext_field;
            }
            return this.model.sub_ext_field;
        },
        enumerable: true,
        configurable: true
    });
    OrderFormComponent.prototype.parseTerm = function (str) {
        var ret = (Number.parseInt(str, 10) % 12) == 0;
        console.log("parseTerm: %s = %s", str, ret);
        return ret;
    };
    OrderFormComponent.prototype.addonLicenseMultiplier = function () {
        if (this.model.sub_addon_license && this.model.sub_addon_license.profile
            && this.model.sub_addon_license.profile.termLength) {
            var months = Number.parseInt(this.model.sub_addon_license.profile.termLength, 10);
            if ((months % 12) == 0) {
                console.log("addonLicenseMultiplier: term is " + Math.floor(months / 12) + " years");
                return Math.floor(months / 12);
            }
            else {
                console.log("addonLicenseMultiplier: term is " + months % 12 + " months");
                console.log("multiplier is " + this.model.sub_addon_license.months_left);
                return this.model.sub_addon_license.months_left;
            }
        }
        else {
            console.log("addonLicenseMultiplier: 1");
            return 1;
        }
    };
    Object.defineProperty(OrderFormComponent.prototype, "addonIsExpired", {
        get: function () {
            var now = new Date(Date.now());
            var exp = new Date(this.model.sub_addon_license.expiration);
            return now > exp;
        },
        enumerable: true,
        configurable: true
    });
    OrderFormComponent.prototype.dump = function (obj) { return JSON.stringify(obj, null, 2); };
    Object.defineProperty(OrderFormComponent.prototype, "diagnostic", {
        get: function () { return JSON.stringify(this.model, null, 2); },
        enumerable: true,
        configurable: true
    });
    OrderFormComponent.prototype.confirmOrderDate = function () {
        var tomorrow = new Date();
        //tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        tomorrow.setMilliseconds(0);
        var tomorrowTime = tomorrow.getTime();
        var orderTime = new Date(this.model.order_date.month + '/' + this.model.order_date.day + '/' + this.model.order_date.year).getTime();
        console.log("orderTime: " + orderTime.toString() + ", today: " + tomorrowTime.toString());
        if (orderTime < tomorrowTime) {
            this.model.warnOrderDate = "This order date is in the past.  Please ensure that you have correctly set this order date.";
        }
        else {
            delete this.model.warnOrderDate;
        }
    };
    /** Drag and drop handlers **/
    OrderFormComponent.prototype.uploadFileSimulator = function (index) {
        var _this = this;
        setTimeout(function () {
            /* if ( index === this.files.length ) {
              return;
            } else */ {
                var progressInterval_1 = setInterval(function () {
                    if (_this.model.files[index].progress === 100) {
                        clearInterval(progressInterval_1);
                    }
                    else {
                        _this.model.files[index].progress += 5;
                    }
                }, 200);
            }
        }, 1000);
    };
    OrderFormComponent.prototype.prepareFilesList = function (files) {
        var _loop_1 = function (item) {
            if (item.size > 16 * 1024 * 1024) {
                item.progress = 100;
                item.status = '#d98989';
                item.error = 'File size is ' + this_1.formatBytes(item.size) + '; maximum upload size is 16MB.';
                this_1.model.files.push(item);
                return "continue";
            }
            item.progress = 0;
            item.status = 'initializing';
            item.formdata = new FormData();
            item.formdata.append('file', item, item.name);
            console.log("uploading %o", item);
            this_1.http.post(this_1.gatewayUrl + '/files/', item.formdata, {
                reportProgress: true,
                observe: 'events'
            }).subscribe(function (event) {
                console.log('upload got event: %o', event);
                switch (event.type) {
                    case HttpEventType.Sent:
                        item.status = '#303030';
                        item.progress = 0;
                        break;
                    case HttpEventType.Response:
                        if (event.body.status == 'ERROR') {
                            item.status = '#d98989';
                            item.progress = 100;
                            item.error = event.body.error;
                        }
                        else {
                            item.status = '#50dc54';
                            item.progress = 100;
                            item.url = event.body.url;
                        }
                        break;
                    case HttpEventType.UploadProgress: {
                        item.status = '#4c97cb';
                        item.progress = Math.round(event.loaded / event.total * 10000) / 100;
                        break;
                    }
                }
            }, function (error) {
                console.log('upload error: %o', error);
                item.status = '#d98989';
                item.progress = 100;
                item.error = error.error.text || error.message;
            });
            this_1.model.files.push(item);
            //this.uploadFileSimulator(index-1);
        };
        var this_1 = this;
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var item = files_1[_i];
            _loop_1(item);
        }
        this.fileDropEl.nativeElement.value = "";
        // this.uploadFileSimulator(0);
    };
    OrderFormComponent.prototype.onFileDropped = function ($event) {
        console.log('file dropped');
        this.prepareFilesList($event);
    };
    OrderFormComponent.prototype.fileBrowseHandler = function (files) {
        console.log('file browsed');
        this.prepareFilesList(files);
    };
    OrderFormComponent.prototype.deleteFile = function (index) {
        if (this.model.files[index].progress < 100) {
            return;
        }
        var formdata = new FormData();
        formdata.set('file', this.model.files[index].url);
        this.http.post(this.gatewayUrl + '/files/del.php', formdata, {
            observe: 'events'
        }).subscribe(function (event) {
            console.log('delete request got response: %o', event);
        });
        this.model.files.splice(index, 1);
    };
    OrderFormComponent.prototype.formatBytes = function (bytes, decimals) {
        if (decimals === void 0) { decimals = 2; }
        if (bytes === 0) {
            return "0 bytes";
        }
        var k = 1024;
        var dm = decimals <= 0 ? 0 : decimals;
        var sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };
    OrderFormComponent.prototype.submitForm = function (formData) {
        var _this = this;
        console.log("formData: %o", formData);
        // Most form elements use "normal" validators like "required" to tell
        // angular that the control is in an "invalid" state when empty.  When
        // those elements are not necessary, they are pruned from the DOM using
        // Angular's *ngIf construct.  Some of the elements, however, exist at
        // all times and are just hidden using the [hidden] construct instead.
        // These hidden elements need to have their errors cleared manually when
        // the form is in a mode where they are hidden.
        if (this.model.sub_type != 'new' || this.model.sub_tenancy != 'cloud' || this.model.sub_origin != 'existing') {
            formData.form.controls['sub_existing_license'].setErrors(null);
            formData.form.controls['sub_existing_siteadmin'].setErrors(null);
        }
        if (this.model.sub_type != 'addon') {
            formData.form.controls['sub_addon_existing'].setErrors(null);
            formData.form.controls['sub_addon_siteadmin'].setErrors(null);
        }
        if (this.model.sub_tenancy != 'onprem') {
            formData.form.controls['sub_onprem_desc'].setErrors(null);
        }
        if (this.model.mode != 'standard') {
            formData.form.controls['desc1'].setErrors(null);
            formData.form.controls['ver1_edit'].setErrors(null);
            formData.form.controls['unit1'].setErrors(null);
        }
        if (this.model.mode != 'subscription') {
            formData.form.controls['sub_type'].setErrors(null);
            formData.form.controls['sub_qty'].setErrors(null);
        }
        if (this.model.sub_type != 'new') {
            formData.form.controls['sub_tenancy'].setErrors(null);
        }
        if (this.model.sub_type != 'new' || this.model.sub_tenancy != 'cloud') {
            formData.form.controls['sub_origin'].setErrors(null);
        }
        if (this.model.sub_tenancy != 'cloud') {
            formData.form.controls['sub_cloud_desc'].setErrors(null);
        }
        if (this.model.mode != 'subscription' || this.model.sub_type != 'new') {
            formData.form.controls['sub_ver_edit'].setErrors(null);
        }
        /*
        // handle total = not-a-number or zero as error
        console.log('this.total = ' + this.total);
        if ( ! (Number(this.total) > 0 ) ) {
          formData.form.controls['ext_field_1'].setErrors({'required': true});
        } else {
          formData.form.controls['ext_field_1'].setErrors(null);
          this.model.total = this.total;
        }
        */
        if (this.model.mode == 'subscription'
            && this.model.sub_type == 'new'
            && this.model.sub_tenancy == 'cloud'
            && this.model.sub_cloud_desc.includes("usage")
            && Number(this.model.sub_qty) < 500) {
            formData.form.controls['sub_qty'].setErrors({ 'invalid': true });
        }
        if (this.model.mode == 'subscription'
            && this.model.sub_type == 'addon'
            && this.addonIsExpired == true) {
            formData.form.controls['sub_addon_existing'].setErrors({ 'invalid': true });
        }
        for (var name_1 in formData.form.controls) {
            if (formData.form.controls[name_1].invalid) {
                console.log(name_1 + " is invalid");
            }
        }
        document.getElementById("top").scrollIntoView(true);
        if (!formData.valid) {
            return;
        }
        // replacer helper for JSON.stringify to include undefined values
        var includeUndefined = function (_key, value) { return typeof value === 'undefined' ? '' : value; };
        // submit to backend
        var httpFormData = new FormData();
        httpFormData.append('op', 'submitOrder');
        httpFormData.append('orderForm', JSON.stringify(this.model, includeUndefined));
        this.alertService.clear();
        this.loading = true;
        this.http.post(this.gatewayUrl + "/register/index.php", httpFormData).subscribe(function (res) {
            _this.loading = false;
            console.log("form submission response: %o", res);
            _this.model.orderconfirmation = res['order_confirmation'];
            _this.orderForm.nativeElement.innerHTML = res['order_html'];
            document.getElementById("top").scrollIntoView(true);
        }, function (err) {
            _this.loading = false;
            console.log("form submission error: %o", err);
            _this.alertService.error("Error: " + err.message + ".  Please try again later.");
        });
    };
    return OrderFormComponent;
}());
export { OrderFormComponent };
