import { Component, OnInit, Renderer2, NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { AlertService, PortalService } from '../_services/index';
import { CookieService } from 'ngx-cookie-service';
import { License, Host } from '../_models';

import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';

@Component({
    moduleId: module.id,
    templateUrl: 'cloudLicenseAdmin.component.html'
})

export class CloudLicenseAdminComponent implements OnInit {
  model: any = {};
  currentUser: any = {};
  loading: boolean = false;
  licenses: License[] = [];
  hosts: Host[] = [];
  checkedHosts = 0;

  licenseTypes: string[] = [];
  myGroup: FormGroup;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private portalService: PortalService,
      private alertService: AlertService,
      private renderer2: Renderer2,
      private cookies: CookieService,
      private confirmDialogService: ConfirmDialogService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log("currentUser = %o", this.currentUser);
        let msie = /msie\s|trident\//i.test(window.navigator.userAgent);
        if ( msie ) {
          this.router.navigate(["/unsupported"]);
        }
        this.loading = true;
        this.myGroup = new FormGroup({
          licenseTypes: new FormControl()
        });
    }

  ngOnInit() {
    // get licenses for logged in users
    try {
      this.portalService.getCloudLicenseAdminLicenses().subscribe(
        data => {
          console.log("getCloudLicenseAdminLicenses() got data = %o", data);
          if ( data ) {
            if ( !Array.isArray(data.license) ) {
              // coerce to array so that ngFor in html template will be able
              // to iterate over a (single-member) array.
              console.log("CloudLicenseAdminComponent: coerce to array");
              data.license = [data.license];
            }
            this.licenses = data.license;
            this.licenses.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
            console.log("updated model: %o", this.licenses);
            this.licenseTypes = this.getLicenseTypes();
            this.model.licenseType="All";
            this.model.selectedLicense = null;
            this.model.editingDescription = false;
            this.model.editingUsers = false;
          }
          this.loading = false;
        },
        error => {
          console.log("warning: getLicenses returned error: " + error);
          this.loading = false;
        }
      );
    } catch ( error ) {
      console.log("getLicenses() failed: " + error);
    }
  }

  getLicenseTypes() {
    var types: any = [ 'All' ];
    this.licenses.forEach(license => {
      //console.log("getLicenseTypes: processing %o", license);
      if ( license.profile == null || license.profile.name == null ) {
        console.log("unable to find profile name for license: " + license.name);
      } else {
        types.push(license.profile.name);
      }
    });
    types = Array.from(new Set(types)); // push Array through a Set (and back to Array) to remove duplicates
    console.log("returning types: %o", types);
    return types;
  }

  selectLicense(license: License) {
    console.log("selected license %o", license);
    this.model.selectedLicense=license;
    this.loading = true;
    this.portalService.getHostsUsingLicense(license.id).subscribe(
      data => {
        console.log("getHostsUsingLicense: got %o", data);
        if ( data ) {
          if ( !Array.isArray(data.host) ) {
            //coerce to Array
            data.host = [data.host];
          }
          this.hosts = data.host;
          this.hosts.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
          // prefix 12 zeros (the most there can be) then take the last 12 digits, then group in
          // twos, and insert colons between them (to turn it into a MAC address)
          // if we want the colons, use: .match(/.{1,2}/g).join(":")
          this.hosts.forEach((host) => host.hardwareid = ('000000000000'+host.hardwareid).substr(-12));
        }
        this.checkedHosts = 0;
        this.loading = false;
      }
    )
  }

  unselectLicense() {
    this.model.selectedLicense = null;
    this.model.editingDescription = false;
    this.model.editingUsers = false;
    this.hosts = [];
  }

  getLicenseExpiration(license: License): String {
    if ( license.profile.session == 'expires' ) {
      return license.expiration_date.split(' ')[0];
    } else {
      return "permanent";
    }
  }

  getSupportExpiration(license: License): String {
    if ( license.profile.support == 'expires' ) {
      return license.expiration_date.split(' ')[0];
    } else {
      return "permanent";
    }
  }

  editDescription() {
    this.model.editingDescription = true;
    this.model.description = this.model.selectedLicense.description;
  }

  cancelEditDescription() {
    this.model.editingDescription = false;
  }

  saveDescription() {
    this.loading = true;
    this.model.selectedLicense.description = this.model.description;
    this.model.editingDescription = false;
    try {
      this.portalService.updateLicenseDescription(this.model.selectedLicense.id, this.model.description).subscribe(
        data => {
          console.log("saveDescription() got data = %o", data);
          this.loading = false;
        },
        error => {
          console.log("warning: saveDescription returned error: " + error);
        }
      );
    } catch ( error ) {
      this.loading = false;
      console.log("saveDescription() failed: " + error);
    }
  }

  editUsers() {
    this.model.editingUsers = true;
    this.model.users = this.model.selectedLicense.concurrent_use_limit;
  }

  cancelEditUsers() {
    this.model.editingUsers = false;
  }

  saveUsers() {
    this.loading = true;
    this.model.selectedLicense.concurrent_use_limit = this.model.users;
    this.model.editingUsers = false;
    try {
      this.portalService.updateLicenseUsers(this.model.selectedLicense.id, this.model.users).subscribe(
        data => {
          console.log("saveusers got data = %o", data);
          this.loading = false;
        },
        error => {
          console.log("warning: saveUsers() got error: " + error);
        }
      );

    } catch(error) {
      this.loading = false;
      console.log("saveUsers() failed: " + error);
    }
  }

  updateCheckedHosts() {
    let newCount = 0;
    this.hosts.forEach(host => {
      if ( host['checked'] ) {
        newCount++;
      }
    });
    this.checkedHosts = newCount;
    console.log("updated checked hosts to " + this.checkedHosts);
  }

  deactivateHosts() {
    var hostIds: Number[] = [];
    var runningSessions: any = 0; // this is "any" because for some weird reason you can't add NUmber and Number
    this.hosts.forEach(host => {
      if ( host['checked'] ) {
        hostIds.push(host.id);
        if ( host.state == 'ONLINE' ) {
          runningSessions += host.sessions;
        }
      }
    });

    var deactivateTitle, deactivateMsg;

    if ( hostIds.length > 1 ) {
      deactivateTitle = "Deactivate Hosts";
      if ( runningSessions > 0 ) {
        deactivateMsg = "There are sessions running on these hosts.  If you deactivate "
          + "GO-Global, users who are connected to these sessions will be disconnected, "
          + "and they will be unable to reconnect to their sessions until GO-Global is "
          + "re-activated on the hosts by running the GO-Global Activation Wizard.  Are "
          + "you sure you want to deactivate GO-Global on these hosts?";
      } else {
        deactivateMsg = "If you deactivate GO-Global, users will be unable to start "
          + "sessions on these hosts until GO-Global is re-activated by running the "
          + "GO-Global Activation Wizard on the hosts.  Are you sure you want to "
          + "deactivate GO-Global on these hosts?";
      }
    } else {
      deactivateTitle = "Deactivate Host";
      if ( runningSessions > 0 ) {
        deactivateMsg = "There are sessions running on this host.  If you deactivate "
          + "GO-Global, users who are connected to these sessions will be disconnected, "
          + "and they will be unable to reconnect to their sessions until GO-Global is "
          + "re-activated on the host by running the GO-Global Activation Wizard.  Are "
          + "you sure you want to deactivate GO-Global on this host?";
      } else {
        deactivateMsg = "If you deactivate GO-Global, users will be unable to start "
          + "sessions on this host until GO-Global is re-activated by running the "
          + "GO-Global Activation Wizard on the host.  Are you sure you want to "
          + "deactivate GO-Global on this host?";
      }
    }

    let that = this;
    this.confirmDialogService.confirmThis(deactivateTitle, deactivateMsg, function() {
      // yes
      that.loading = true;
      console.log("deactivating hosts");
      that.portalService.deactivateHosts(hostIds).subscribe(
        data => {
          console.log("deactivateHosts got return: %o", data);
          that.loading = false;
          that.hosts = [];
          that.checkedHosts = 0;
          that.selectLicense(that.model.selectedLicense);
        },
        error => {
          console.log("warning: deactivateHosts got error return: " + error);
          that.loading = false;
        }
      );
    }, function() {
      // no
    });
  }
}
