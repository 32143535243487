import { Component, OnInit, Renderer2, NgZone, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, PortalService } from '../_services/index';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

import { Countries } from '../_helpers/index';
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Component({
  moduleId: module.id,
  templateUrl: 'order_form.component.html',
  styleUrls: [ 'order_form.component.css' ]
})

export class OrderFormComponent implements OnInit {
  @ViewChild("fileDropRef") fileDropEl: ElementRef;
  @ViewChild("orderFormRef") orderForm: ElementRef;

  /*
  orderForm = new FormGroup({
    order_date: new FormControl('')
  })
*/
  loading: Boolean = true;
  gatewayUrl: string;
  model: any = {};
  countries: any = {};
  versions: any = {};
  licenses: any = {};
  currentUser: any = {};

  constructor(
    private ngZone: NgZone,
    private renderer2: Renderer2,
    private router: Router,
    private portalService: PortalService,
    private currencyPipe: CurrencyPipe,
    private alertService: AlertService,
    private http: HttpClient,
  ) {
      this.loading = true;
      this.gatewayUrl = environment.gatewayUrl;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let msie = /msie\s|trident\//i.test(window.navigator.userAgent);
      if ( msie ) {
        this.router.navigate(["/unsupported"]);
      }
      this.countries = Countries.getCountries();
      this.versions['default'] = [
        {id: '', name: ' - Choose One - ', selected: true, disabled: true},
        {id: 'GGW6.3', name: 'GO-Global v6.3'},
        {id: 'GGW6.2', name: 'GO-Global v6.2'},
        {id: 'GGW6.1', name: 'GO-Global v6.1'},
        {id: 'GGW6.0', name: 'GO-Global v6.0'},
        {id: 'GGW5.0', name: 'GO-Global v5.0'},
        {id: 'GGW4.8', name: 'GO-Global v4.8'},
      /*
        {id: 'SSL60W', name: 'GO-Global v6.0 Encryption'},
        {id: 'SSL50W', name: 'GO-Global v5.0 Encryption'},
        {id: 'SSL48W', name: 'GO-Global v4.8 Encryption'},
      */
        {id: 'GGX2.4', name: 'GO-Global for UNIX v2.4'},
        {id: 'GGX2.3', name: 'GO-Global for UNIX v2.3'},
      /*
        {id: 'GGX2.2', name: 'GO-Global for UNIX v2.2'},
        {id: 'PROFSVC', name: 'Professional Services'},
      */
        {id: 'OTHER', name: 'Other - Note in Special Instructions'}
      ];
      this.versions['cloud'] = [
        {id: '', name: ' - Choose One - ', selected: true, disabled: true},
        {id: 'GGW6.3', name: 'GO-Global v6.3'},
        {id: 'GGW6.2', name: 'GO-Global v6.2'},
      ];
      this.versions['onprem'] = [
        {id: '', name: ' - Choose One - ', selected: true, disabled: true},
        {id: 'GGW6.3', name: 'GO-Global v6.3'},
        {id: 'GGW6.2', name: 'GO-Global v6.2'},
        {id: 'GGW6.1', name: 'GO-Global v6.1'},
        {id: 'GGW6.0', name: 'GO-Global v6.0'},
        {id: 'GGW5.0', name: 'GO-Global v5.0'},
      ];
      this.licenses = [
        {id: 'LIC-0', name: 'LIC-0', description: 'This list of licenses is fake.'},
        {id: 'LIC-1', name: 'LIC-1', description: 'Tom Castanzo\'s Subscription License'},
        {id: 'LIC-2', name: 'LIC-2', description: 'Troy\'s Old Expired License'}
      ];
  }

  ngOnInit() {
    const today = new Date();
    this.model.order_date = { 'year': today.getFullYear(),
                              'month': today.getMonth()+1,
                              'day': today.getDate()
                            };
    this.model.validated = {};
    this.model.notfound = {};
    this.model.found = {};
    this.model.duplicateContact = {};
    this.model.mode = 'unknown';
    this.model.payment_method = '';
    this.model.cc_type = '';
    this.model.maint_qty = 1;
    this.model.partner_type = 'reseller';
    this.model.sub_type = '';
    this.model.sub_dur = '';
    this.model.sub_tenancy = '';
    this.model.sub_origin = '';
    this.model.sub_addon_license = {};
    this.model.sub_addon_license.siteadmin1 = '';
    this.model.sub_addon_license.siteadmin2 = '';
    this.model.sub_addon_license.siteadmin3 = '';
    this.model.sub_existing_trial_license = { 'siteadmin1' : '', 'siteadmin2': '', 'siteadmin3': '' };
    this.model.sub_current_qty = 0;
    this.model.sub_qty = '';
    this.model.ver1="";
    this.model.show_readme = true;
    this.model.debug = false;
    this.model.files = [];
    this.model.enabled_modes='';
    this.loading = true;
    this.portalService.getUserAccountInfo()
      .subscribe(
        data => {
          this.loading = false;
          console.log(" data = %o", data);
          this.model.user = data.user;
          this.model.billto_contact_name = data.user.firstname + " " + data.user.lastname;
          this.model.billto_contact_email = data.user.email;
          this.model.billto_phone = data.user.phone;
          this.model.billto_company = data.user.account.name;
          /*
          if ( data.user.account.city != '' || data.user.account.state != '' || data.user.account.postalCode != '' ) {
            data.user.account.streetaddress += "\n" + [[data.user.account.city, data.user.account.state].join(', '),
              data.user.account.postalCode].join(' ');
          }
          */
          let splits = data.user.account.streetaddress.split('\n');
          switch(splits) {
            default:
            case 4:
              this.model.billto_address4 = splits[3];
            case 3:
              this.model.billto_address3 = splits[2];
            case 2:
              this.model.billto_address2 = splits[1];
            case 1:
              this.model.billto_address = splits[0];
            }
            /*
            if ( this.countries.find((c: any) => c.name == data.user.account.country) == undefined) {
              this.countries.unshift({id: data.user.account.country, name: data.user.account.country});
            }
            */
            this.model.billto_country = data.user.account.country;
            this.model.billto_email = data.user.email;

            /* Enable modes of the form based on values in the Pricing Level */
            var matches = data.user.account.pricingLevel.match(/\[(.*?)\]/);
            if ( matches ) {
              this.model.enabled_modes=matches[1];
            }
            if ( data.user.account.type == "Admin" ) {
              this.model.enabled_modes='SPC';
            }
            console.log("enabled order modes = "+this.model.enabled_modes);
          },
          error => {
            this.loading = false;
            this.alertService.error("Error getting user information: " + error + ". Please login again.");
            this.router.navigate(["/orderform"]);
          }
      );
  }

  toggleReadme() {
    this.model.show_readme = (this.model.show_readme == true) ? false : true;
  }

  isNotANumber(num: any): Boolean {
    return Number.isNaN(Number(num));
  }

  unformat(amount: string) : number {
    //console.log(`unformat '${amount}'`);
    if ( amount == undefined || amount == null || amount == '' ) { return 0; }
    amount = amount.replace(/\$/g, '').replace(/,/g, '');
    return parseFloat(amount);
  }

  untransformAmount(event: any) {
    event.target.value = this.unformat(event.target.value);
    event.target.select();
  }

  transformAmount(event: any) {
    event.target.value = this.currencyPipe.transform(event.target.value, '$');
  }

  setFocus(selector: string): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        console.log("focusing on: %o", selector);
        try {
          this.renderer2.selectRootElement(selector).focus();
        } catch (error) {
          console.log("trying to focus on %o: %s", selector, error);
          setTimeout( () => {
            this.setFocus(selector);
          }, 50)
        }
      }, 0);
    });
  }

  extended(qty: number, unitprice: number): string {
    return this.currencyPipe.transform(qty * unitprice, '$');
  }

  get total(): string {
    let total = 0;
    switch(this.model.mode) {
      case 'standard':
        if ( this.model.hasOwnProperty('qty1') && this.model.hasOwnProperty('unit1') ) {
          total += this.model.qty1 * this.unformat(this.model.unit1);
        }
        if ( this.model.hasOwnProperty('qty2') && this.model.hasOwnProperty('unit2') ) {
          total += this.model.qty2 * this.unformat(this.model.unit2);
        }
        break;
      case 'subscription':
        if ( this.model.hasOwnProperty('sub_qty') && this.model.hasOwnProperty('sub_units') ) {
          //total += this.model.sub_qty * this.unformat(this.model.sub_units);
          if ( this.model.sub_cloud_desc && this.model.sub_cloud_desc.includes('usage') ) {
            this.model.total =  "N/A";
          } else {
            if ( this.model.sub_type=='addon' ) {
              total += this.extPrice * this.addonLicenseMultiplier();
            } else {
              total += this.extPrice;
            }
          }
        }

        break;
      case 'maint_renew':
        if ( this.model.hasOwnProperty('maint_unit') ) {
          total += this.model.maint_unit;
        }
        break;
    }
    console.log("total=%o", total);
    if ( Number.isNaN(Number(total)) == true ) {
      return "$0.00";
    }
    this.model.total = this.currencyPipe.transform(total, '$');
    return this.model.total;
  }

  get numericTotal(): number {
    return this.unformat(this.total);
  }

  onSubCloudDescChange(event: any) {
    this.model.sub_units = '$0.00';
    this.model.sub_total = 0;
    if ( this.model.sub_cloud_desc.includes('usage') ) {
      this.model.total = 'N/A';
    } else {
      this.model.total = 0;
    }
  }
  onSubOnPremDescChange(event: any) {
    this.model.sub_units = '$0.00';
    this.model.sub_total = 0;
  }

  checkContactsForDupes() {
    var emailFields = document.querySelectorAll("input[email]");
    this.model.duplicateContact = {};
    console.log("checking for duplicate emails");
    Array.from(emailFields).forEach((element: HTMLInputElement) => {
      Array.from(emailFields).forEach((element2: HTMLInputElement) => {
        if ( element.name != element2.name && element.value != ""
          && this.model.duplicateContact[element.name] != true) {
          console.log("checking " + element.name + " vs. " + element2.name);
          if( element.value == element2.value ) {
            console.log(">> found duplicate: " + element.value + " == " + element2.value);
            if ( this.model[element.name+"_firstname"] != undefined ) {
              this.model.duplicateContact[element2.name] = true;
              this.model.duplicateContact[element2.name+"_firstname"] = this.model[element.name+"_firstname"];
              this.model.duplicateContact[element2.name+"_lastname"] = this.model[element.name+"_lastname"];
            } else if ( this.model[element2.name+"_firstname"] != undefined ) {
              this.model.duplicateContact[element.name] = true;
              this.model.duplicateContact[element.name+"_firstname"] = this.model[element2.name+"_firstname"];
              this.model.duplicateContact[element.name+"_lastname"] = this.model[element2.name+"_lastname"];
            } else {
              console.log("unable to determine which is the 'real' field");
            }
          }
        }
      });
    });
  }

  checkContact(event: any) {
    const controlName = event.target.name;
    console.log("checkContact target: %o", event.target);
    if ( !event.target.classList.contains('ng-invalid')
      && event.target.value != "" && event.target.value != this.model.validated[controlName] ) {
      this.loading = true;
      this.portalService.verifyEmail(event.target.value)
        .subscribe(
          data => {
            this.loading = false;
            console.log("verify email: %o", data);
            if ( data.user._found == 'true' ) {
              this.model.validated[controlName] = event.target.value;
              this.model.notfound[controlName] = false;
              this.model.found[controlName] = data.user;
            } else {
              delete this.model.found[controlName];
              this.model.notfound[controlName] = true;
              this.setFocus("#" + controlName + "_firstname");
            }
          },
          error => {
            this.loading = false;
            this.alertService.error('Unknown error : ' + error + '.');
            console.log("error: %o", error);
          }
      );
    } else if ( event.target.value == this.model.validated[controlName] ) {
      this.model.notfound[controlName] = false;
    }
    this.checkContactsForDupes();

  }

  updateContact(event: any) {
    const controlName = event.target.name;
    if ( event.target.value != this.model.validated[controlName] ) {
      this.model.notfound[controlName] = false;
      delete this.model.found[controlName];
      delete this.model.validated[controlName];
      delete this.model[controlName+"_firstname"];
      delete this.model[controlName+"_lastname"];
    }
  }

  resetExistingLicense() {
    // called when the existing trial license field is modified
    this.model.sub_existing_trial_license={ 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': ''};
  }

  resetSubscriptionType() {
    this.model.sub_dur='';
    this.model.sub_tenancy='';
    this.model.sub_existing_license='';
    this.model.sub_existing_siteadmin='';
    this.model.sub_existing_trial_license={ 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': ''};
    this.model.sub_existing_trial_license_error='';
    this.model.sub_addon_existing='';
    this.model.sub_addon_siteadmin='';
    this.model.sub_addon_license={ 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': ''};
    this.model.siteadmin1='';
    this.model.siteadmin2='';
    this.model.siteadmin3='';
    delete this.model.found.siteadmin1;
    delete this.model.found.siteadmin2;
    delete this.model.found.siteadmin3;
    this.model.sub_qty = '';
  }

  onTenancyChange() {
    if ( this.model.sub_tenancy != 'onprem' ) {
      this.model.sub_cloud_desc = '';
    }
    if ( this.model.sub_tenancy != 'cloud' ) {
      this.model.sub_onprem_desc = '';
    }
    this.model.sub_ver = '';
    this.model.sub_existing_license='';
    this.model.sub_existing_siteadmin='';
    this.model.sub_existing_trial_license={ 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': ''};
    this.model.sub_existing_trial_license_error='';
    this.model.sub_addon_existing='';
    this.model.sub_addon_siteadmin='';
    this.model.sub_addon_license={ 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': ''};
  }

  onOriginChange() {
    this.model.sub_existing_license='';
    this.model.sub_existing_siteadmin='';
    this.model.sub_existing_trial_license={ 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': ''};
    this.model.sub_existing_trial_license_error='';
    this.model.sub_addon_existing='';
    this.model.sub_addon_siteadmin='';
    this.model.sub_addon_license={ 'siteadmin1': '', 'siteadmin2': '', 'siteadmin3': ''};
  }

  toggleDebug(): void {
    this.model.debug = (this.model.debug ? false : true);
    console.log("debug is " + (this.model.debug ? "true" : "false"));
  }

  lookupExistingTrial() {
    // get the specified trial license and display relevant bits for Confirmation
    if ( this.model.sub_existing_license != undefined
      && this.model.sub_existing_license != ''
      && this.model.sub_existing_siteadmin != undefined
      && this.model.sub_existing_siteadmin != '' ) {
      if ( !this.model.sub_existing_license.includes('LIC-') ) {
        this.model.sub_existing_license = 'LIC-' + this.model.sub_existing_license;
      }
      this.loading = true;
      this.portalService.lookupExistingTrial(this.model.sub_existing_license, this.model.sub_existing_siteadmin)
        .subscribe(
          data => {
            this.loading = false;
            console.log("lookupExistingTrial: %o", data);
            if ( data.failure ) {
              this.model.sub_existing_trial_license = {};
              this.model.sub_existing_trial_license_error = data.failure;
            } else {
              delete this.model.sub_existing_trial_license_error;
              this.model.sub_existing_trial_license = data.license;
              this.model.sub_qty = 1;
              this.model.sub_desc = '';
            }
          },
          error => {
            this.loading = false;
            this.alertService.error('Unknown error: ' + error + '.');
            console.log("error: %o", error);
          }
        );
    } else {
      this.model.sub_existing_trial_license = {};
    }
  }

  lookupExistingLicense() {
    // get the specified existing cloud licenses to add seats to it
    if ( this.model.sub_addon_existing != undefined
      && this.model.sub_addon_existing != ''
      && this.model.sub_addon_siteadmin != undefined
      && this.model.sub_addon_siteadmin != '' ) {
      if ( !this.model.sub_addon_existing.includes('LIC-') ) {
        this.model.sub_addon_existing = 'LIC-' + this.model.sub_addon_existing;
      }
      this.loading = true;
      this.portalService.lookupExistingLicense(this.model.sub_addon_existing, this.model.sub_addon_siteadmin)
        .subscribe(
          data => {
            this.loading = false;
            console.log("lookupExistingLicense: %o", data);
            if ( data.failure ) {
              this.model.sub_addon_license = {};
              this.model.sub_addon_license_error = data.failure;
            } else {
              delete this.model.sub_addon_license_error;
              this.model.sub_addon_license = data.license;
              this.model.sub_current_qty = data.license.seats;
              this.model.sub_qty = 0;
              this.model.sub_desc = data.license.profile.name;
              this.model.sub_ver = data.license.version;
              console.log("existing license: %o", data.license);

              // figure out the number of months left in this licenses
              let exp_date = new Date(data.license.expiration);
              let today = new Date(Date.now());
              let months_left = 1 +
                  exp_date.getMonth() - today.getMonth() +
                  (12 * ( exp_date.getFullYear() - today.getFullYear() ) );
              if ( months_left < 0 ) { months_left = 0; }
              this.model.sub_addon_license.months_left = months_left;
            }
          },
          error => {
            this.loading = false;
            this.alertService.error('Unknown error: ' + error + '.');
            console.log("error: %o", error);
          }
        );
    } else {
      this.model.sub_addon_license = {};
    }
  }

  get extPrice() {
    var multiplier=1;
    if (this.model.sub_tenancy=='cloud') {
      if ( !this.model.sub_cloud_desc.includes('usage') )
      switch(this.model.sub_cloud_desc) {
        case '3month':
        case '3monthsso':
          multiplier = 3;
      }
    } else if (this.model.sub_tenancy=='onprem') {
      switch(this.model.sub_onprem_desc) {
        case '2month': multiplier=2; break;
        case' 3monthsso': case '3month': multiplier=3; break;
      }
    } else if ( this.model.sub_addon_license.profile != undefined ) {
      return this.addonPrice;
    }
    var val = this.model.sub_qty * this.model.sub_units * multiplier;
    if ( isNaN(val) ) val = 0;
    console.log('compute extPrice qty ' + this.model.sub_qty + ' unit ' + this.model.sub_units + ' = val ' + val);
    this.model.sub_ext_field = '$' + val.toFixed(2);
    if ( this.model.mode=='subscription' ) {
      this.model.total = this.model.sub_ext_field;
    }
    return val;
  }

  get addonPrice() : number {
    let num = 0;
    if ( this.model.sub_addon_license.profile != undefined ) {
      if ( this.parseTerm (this.model.sub_addon_license.profile.termLength) == true ) {
        num = this.model.sub_addon_license.months_left * this.model.sub_qty * (this.model.sub_units/12);
      } else {
        var multiplier = this.model.sub_addon_license.months_left;
        console.log("addonPrice: multiplier = " + multiplier + ", qty="+this.model.sub_qty+", unitprice="+this.model.sub_units);
        num = Number(multiplier * this.model.sub_qty * this.model.sub_units);
      }
      if ( isNaN(num) ) { num = 0; }
      if ( num < 0 ) { num = 0; }
      this.model.sub_ext_field = '$' + num.toFixed(2);
      //console.log('addonPrice set sub-ext_field to ' + this.model.sub_ext_field);
      this.model.total = this.model.sub_ext_field;
    }
    return this.model.sub_ext_field;
  }

  parseTerm(str: string) : boolean {
    var ret = (Number.parseInt(str, 10) % 12) == 0;
    console.log("parseTerm: %s = %s", str, ret);
    return ret;
  }
  addonLicenseMultiplier() : number {
    if ( this.model.sub_addon_license && this.model.sub_addon_license.profile
        && this.model.sub_addon_license.profile.termLength) {
      let months = Number.parseInt(this.model.sub_addon_license.profile.termLength, 10);
      if ( (months % 12) == 0 ) {
        console.log("addonLicenseMultiplier: term is " + Math.floor(months/12) + " years");
        return Math.floor(months/12);
      } else {
        console.log("addonLicenseMultiplier: term is " + months % 12 + " months");
        console.log("multiplier is " + this.model.sub_addon_license.months_left);
        return this.model.sub_addon_license.months_left;
      }
    } else {
      console.log("addonLicenseMultiplier: 1");
      return 1;
    }
  }

get addonIsExpired() : boolean {
  var now = new Date(Date.now());
  var exp = new Date(this.model.sub_addon_license.expiration);
  return now > exp;
}

  dump(obj: any): string { return JSON.stringify(obj, null, 2); }

  get diagnostic() { return JSON.stringify(this.model, null, 2); }

  confirmOrderDate() {
    let tomorrow = new Date();
    //tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    var tomorrowTime = tomorrow.getTime();
    var orderTime = new Date(this.model.order_date.month + '/' + this.model.order_date.day + '/' + this.model.order_date.year).getTime();

    console.log("orderTime: " + orderTime.toString() + ", today: " + tomorrowTime.toString());

    if ( orderTime < tomorrowTime ) {
      this.model.warnOrderDate = "This order date is in the past.  Please ensure that you have correctly set this order date.";
    } else {
      delete this.model.warnOrderDate;
    }
  }

  /** Drag and drop handlers **/
  uploadFileSimulator(index: number) {
    setTimeout(() => {
      /* if ( index === this.files.length ) {
        return;
      } else */ {
        const progressInterval = setInterval(() => {
          if ( this.model.files[index].progress === 100 ) {
            clearInterval(progressInterval);
          } else {
            this.model.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      if ( item.size > 16 * 1024 * 1024 ) {
        item.progress = 100;
        item.status = '#d98989';
        item.error = 'File size is ' + this.formatBytes(item.size) + '; maximum upload size is 16MB.';
        this.model.files.push(item);
        continue;
      }
      item.progress = 0;
      item.status = 'initializing';
      item.formdata = new FormData();
      item.formdata.append('file', item, item.name);
      console.log("uploading %o", item);
      this.http.post(this.gatewayUrl+'/files/', item.formdata, {
        reportProgress: true,
        observe: 'events'
      }).subscribe( (event: HttpEvent<any>) => {
        console.log('upload got event: %o', event);
        switch(event.type) {
          case HttpEventType.Sent:
            item.status = '#303030';
            item.progress = 0;
            break;
          case HttpEventType.Response:
            if ( event.body.status == 'ERROR' ) {
              item.status = '#d98989';
              item.progress = 100;
              item.error = event.body.error;
            } else {
              item.status = '#50dc54';
              item.progress = 100;
              item.url = event.body.url;
            }
            break;
          case HttpEventType.UploadProgress: {
            item.status = '#4c97cb';
            item.progress = Math.round(event.loaded / event.total * 10000)/100;
            break;
          }
        }
      },
      error => {
        console.log('upload error: %o', error);
        item.status = '#d98989';
        item.progress = 100;
        item.error = error.error.text || error.message;
      })
      this.model.files.push(item);
      //this.uploadFileSimulator(index-1);
    }
    this.fileDropEl.nativeElement.value = "";
    // this.uploadFileSimulator(0);
  }
  onFileDropped($event: any[]) {
    console.log('file dropped');
    this.prepareFilesList($event);
  }
  fileBrowseHandler(files: any[]) {
    console.log('file browsed');
    this.prepareFilesList(files);
  }
  deleteFile(index: number) {
    if ( this.model.files[index].progress < 100 ) {
      return;
    }
    var formdata = new FormData();
    formdata.set('file', this.model.files[index].url);
    this.http.post(this.gatewayUrl + '/files/del.php', formdata, {
      observe: 'events'
    }).subscribe( (event: HttpEvent<any>) => {
      console.log('delete request got response: %o', event);
    })
    this.model.files.splice(index,1);
  }
  formatBytes(bytes: number, decimals = 2) {
    if ( bytes === 0 ) {
      return "0 bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["bytes","KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  submitForm(formData: any) {
    console.log("formData: %o", formData);

    // Most form elements use "normal" validators like "required" to tell
    // angular that the control is in an "invalid" state when empty.  When
    // those elements are not necessary, they are pruned from the DOM using
    // Angular's *ngIf construct.  Some of the elements, however, exist at
    // all times and are just hidden using the [hidden] construct instead.
    // These hidden elements need to have their errors cleared manually when
    // the form is in a mode where they are hidden.
    if ( this.model.sub_type!='new' || this.model.sub_tenancy!='cloud' || this.model.sub_origin!='existing' ) {
      formData.form.controls['sub_existing_license'].setErrors(null);
      formData.form.controls['sub_existing_siteadmin'].setErrors(null);
    }
    if ( this.model.sub_type!='addon' ) {
      formData.form.controls['sub_addon_existing'].setErrors(null);
      formData.form.controls['sub_addon_siteadmin'].setErrors(null);
    }
    if ( this.model.sub_tenancy!='onprem' ) {
      formData.form.controls['sub_onprem_desc'].setErrors(null);
    }
    if ( this.model.mode!='standard' ) {
      formData.form.controls['desc1'].setErrors(null);
      formData.form.controls['ver1_edit'].setErrors(null);
      formData.form.controls['unit1'].setErrors(null);
    }
    if ( this.model.mode!='subscription' ) {
      formData.form.controls['sub_type'].setErrors(null);
      formData.form.controls['sub_qty'].setErrors(null);
    }
    if ( this.model.sub_type!='new' ) {
      formData.form.controls['sub_tenancy'].setErrors(null);
    }
    if ( this.model.sub_type!='new' || this.model.sub_tenancy!='cloud' ) {
      formData.form.controls['sub_origin'].setErrors(null);
    }
    if ( this.model.sub_tenancy!='cloud' ) {
      formData.form.controls['sub_cloud_desc'].setErrors(null);
    }
    if ( this.model.mode!='subscription' || this.model.sub_type!='new' ) {
      formData.form.controls['sub_ver_edit'].setErrors(null);
    }
    /*
    // handle total = not-a-number or zero as error
    console.log('this.total = ' + this.total);
    if ( ! (Number(this.total) > 0 ) ) {
      formData.form.controls['ext_field_1'].setErrors({'required': true});
    } else {
      formData.form.controls['ext_field_1'].setErrors(null);
      this.model.total = this.total;
    }
    */
    if ( this.model.mode == 'subscription'
      && this.model.sub_type == 'new'
      && this.model.sub_tenancy=='cloud'
      && this.model.sub_cloud_desc.includes("usage")
      && Number(this.model.sub_qty) < 500 ) {
      formData.form.controls['sub_qty'].setErrors({'invalid': true});
    }
    if ( this.model.mode == 'subscription'
      && this.model.sub_type == 'addon'
      && this.addonIsExpired == true) {
      formData.form.controls['sub_addon_existing'].setErrors({'invalid': true});
    }

    for(const name in formData.form.controls) {
      if ( formData.form.controls[name].invalid) {
        console.log(name + " is invalid");
      }
    }

    document.getElementById("top").scrollIntoView(true);
    if (! formData.valid ) {
      return;
    }

    // replacer helper for JSON.stringify to include undefined values
    const includeUndefined = (_key: any, value: any) => typeof value === 'undefined' ? '' : value;

    // submit to backend
    let httpFormData = new FormData();
    httpFormData.append('op', 'submitOrder');
    httpFormData.append('orderForm', JSON.stringify(this.model, includeUndefined));
    this.alertService.clear();
    this.loading = true;
    this.http.post(this.gatewayUrl + "/register/index.php", httpFormData).subscribe( res => {
      this.loading = false;
      console.log("form submission response: %o", res);
      this.model.orderconfirmation = res['order_confirmation'];
      this.orderForm.nativeElement.innerHTML = res['order_html'];
      document.getElementById("top").scrollIntoView(true);
    }, err => {
      this.loading=false;
      console.log("form submission error: %o", err);
      this.alertService.error("Error: " + err.message + ".  Please try again later.");
    });
  }

}
