﻿import { Component, Renderer2, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService, PortalService } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'register.component.html'
})

export class RegisterComponent implements OnInit {
    model: any = {};
    loading: Boolean = false;

    constructor(
        private router: Router,
        private portalService: PortalService,
        private alertService: AlertService,
        private renderer2: Renderer2) {
        }

    ngOnInit() {
      let autofocus = this.renderer2.selectRootElement('[autofocus]');
      autofocus.focus();
    }

    register() {
        this.loading = true;
        this.model.realCustomerInfo = "Company: " + encodeURIComponent(this.model.company);
        if (this.model.customerInfo != undefined) {
            this.model.realCustomerInfo += encodeURIComponent("\nUser provided ID to portal: " + this.model.customerInfo);
        }
        this.model.customerInfo = this.model.realCustomerInfo;
        console.log("creating user %o", this.model);
        this.portalService.createPortalUserAccount(this.model)
            .subscribe(
                data => {
                    console.log("RegisterComponent:register() got data = %o", data);
                    if ( data ) {
                        this.router.navigate(['/pollActivation'],
                            { queryParams: {username: this.model.emailaddress}});
                        this.alertService.success('Sign Up Completed.', true);
                    }
                },
                error => {
                    if ( error.toString().includes("portal.account.exists.no.password")) {
                        this.alertService.error("The e-mail address '" + this.model.emailaddress + "' is already registered, but does not have a password set. Please set your password.", true);
                        this.loading = false;
                        this.router.navigate(['/forgotPassword/' + this.model.emailaddress]);
                    } else if ( error.toString().includes("portal.account.exists") ) {
                        this.alertService.error("The e-mail address '"+ this.model.emailaddress + "' is already registered.  Please sign-in using '" + this.model.emailaddress + "'.", true);
                        this.loading = false;
                        this.router.navigate(['/signin']);
                    } else {
                        this.alertService.error(error);
                        this.loading = false;
                    }
                });
    }
}
