import { Component, OnInit, Renderer2, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl } from '@angular/forms';
import { AlertService, PortalService } from '../_services/index';
import { CookieService } from 'ngx-cookie-service';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as version from "../version";
import { Countries } from "../_helpers/index";

@Component({
    moduleId: module.id,
    selector: 'ngbd-datepicker-popup',
    templateUrl: 'demo.component.html'
})

export class DemoComponent implements OnInit {
    currentUser: any = {};
    countries: any = {};
    versions: any = {};

    model: any = {};
    loading = false;
    returnUrl: string;

    constructor(
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private router: Router,
        private portalService: PortalService,
        private alertService: AlertService,
        private renderer2: Renderer2) {
          this.loading = true;
          this.model.lockedout = false;
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          console.log("currentUser=%o", this.currentUser);
          var username = top.document.getElementById("signed_in_username");

          this.model.appversion = version;

          this.versions = [
            {id: "6.3", name: "GO-Global v6.3"},
            {id: "6.2", name: "GO-Global v6.2"},
            {id: "6.1", name: "GO-Global v6.1"},
            {id: "6.0", name: "GO-Global v6.0"},
            {id: "5.0", name: "GO-Global v5.0"},
          ];
          this.model.version = "";
          this.countries = Countries.getCountries();
          this.model.username = username.textContent;
          this.model.licensetype='custom';
          this.model.stage=1;

          this.portalService.getUserTrialStatus()
              .subscribe(
                  data => {
                    this.loading = false;
                    console.log("data = %o", data);

                    this.model.accountName = data.accountName;
                    this.model.approveddays = data.transactionTrialLimit;
                    this.model.approveddate = moment().add(this.model.approveddays, 'day').format('YYYY-MM-DD');
                    this.model.approvedtotaldays = data.cumulativeTrialLimit;
                    this.model.approvedusers = data.userLimit;
                    this.model.allowedDays = this.model.approvedtotaldays; //  by default, will update later when we get hostid
                    this.model.lockedout = data.userTrialLevel == "LOCKEDOUT";
                    this.model.accountDefaultDays = data.defaultDays;
                    this.model.accountDefaultUsers = data.defaultUsers;
                    this.model.defaultDays = data.defaultDays;
                    this.model.expiration_days = data.defaultDays;
                    this.update_expiration();
                    this.model.users = data.defaultUsers;
                    this.model.defaultUsers = data.defaultUsers;
                    this.model.defaultDate = moment().add(this.model.defaultDays, 'day').format('YYYY-MM-DD');
                    this.model.hasPerm = false;
                    this.loading = false;
                  },
                  error => {
                    this.loading = false;
                    this.alertService.error('Unknown error: ' + error +". Please login again.");
                    this.router.navigate(["/trial"]);
                  }
              );
        }

    ngOnInit() {
      try {
        let autofocus = this.renderer2.selectRootElement('[autofocus]');
        autofocus.focus();
      } catch (error) {
        console.log("autofocus caught error " + error + ", ignoring");
      }
      //this.alertService.success("Loaded.");
    }

    setFocus(selector: string): void {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.renderer2.selectRootElement(selector).focus();
        }, 0);
      });
    }

    validate_recipient(delivertocontrol: AbstractControl) {
      if ( delivertocontrol.valid == false ) { this.setFocus("[autofocus]"); return ; }

      if ( this.model.deliverto && this.model.deliverto != "" && this.model.deliverto != this.model.validatedRecipient) {
        this.loading=true;
        this.portalService.validateTrialRecipient(this.model.deliverto)
        .subscribe(
          data => {
            console.log("validateTrialRecipient: " + data.validateRecipient);
            this.model.validRecipient = data.validateRecipient;
            this.model.validatedRecipient = this.model.deliverto;
            if ( data.validateRecipient != 'false' ) {
              this.loading = false;
              this.model.firstname = data.firstname;
              this.model.lastname = data.lastname;
              this.model.company = data.company;
              this.model.accountcc = data.accountcc == null ? "" : data.accountcc;
              this.model.channelcc = data.channelcc == null ? "" : data.channelcc;
            } else {
              this.model.firstname = "";
              this.model.lastname = "";
              this.model.company = "";
              this.model.accountcc = "";
              this.model.channelcc = "";
              this.loading = false;
              this.setFocus("[firstname]");
            }
          },
          error => {
            this.alertService.error('Unknown error: ' + error + '. please login again.');
            this.router.navigate(["/trial"]);
          }
        );
      }
    }

    fix_hostid() {
      if ( this.model.hostid )
        this.model.hostid = this.model.hostid.replace(/-/g, "");
    }

    validate_hostid_version() {
      console.log("validating hostid");

      if ( this.model.hostid && this.model.hostid != "" && this.model.version && this.model.version != "" ) {
        this.loading=true;
        this.portalService.validateHostEligibility(this.model.hostid, this.model.version)
        .subscribe(
          data => {
            console.log("validateHostEligibility: %o", data);
            this.model.validHostId = data.validateHostId;
            this.model.validatedHostId = this.model.hostid;
            this.model.allowedDays = data.eligibility._days;
            this.model.hasPerm = data.eligibility._hasPerm == 'true';
            this.loading=false;
            if ( +this.model.allowedDays < 0 ) this.model.allowedDays = 0;

//            if ( +this.model.allowedDays <= +this.model.accountDefaultDays ) {
//              console.log("set defaultDays to " + this.model.allowedDays);
//              this.model.defaultDays = this.model.allowedDays;
//            }
//            if ( +this.model.allowedDays > +this.model.accountDefaultDays ) {
//              console.log("set defaultDays to default " + this.model.accountDefaultDays);
//              this.model.defaultDays = this.model.accountDefaultDays;
//            } else if ( +this.model.allowedDays > +this.model.defaultDays ) {
//              console.log("set defaultDays to transaction max " + this.model.approveddays);
//              this.model.defaultDays = this.model.approveddays;
//            }

//            if ( +this.model.expiration_days == undefined ) {
//              if ( +this.model.allowedDays == +this.model.approvedtotaldays ) {
//                // allowed == max, no previous trial
//                this.model.expiration_days = this.model.accountDefaultDays;
//              } else {
//                // previous trial exists
//                if ( +this.model.allowedDays < +this.model.accountDefaultDays ) {
//                  // fewer than default days remaining
//                  this.model.expiration_days = this.model.allowedDays;
//                } else {
//                  this.model.expiration_days = this.model.accountDefaultDays;
//                }
//              }
//              this.update_expiration();
//            }
//            this.model.defaultDate = moment().add(this.model.defaultDays, 'day').format('YYYY-MM-DD');
//            if ( +this.model.users == undefined ) {
//              this.model.users = this.model.accountDefaultUsers;
//            }
          },
          error => {
            this.loading=false;
            this.alertService.error('Unknown error: ' + error + '. please login again.');
            this.router.navigate(["/trial"]);
          }
        );
      }
    }

    update_expiration_days() {
      console.log("update expiration days");
      if ( this.model.expiration == null ) return;

      let expiration = ('0000' + this.model.expiration.year).slice(-4) + "-" +
                       ('00' + this.model.expiration.month).slice(-2) + "-" +
                       ('00' + this.model.expiration.day).slice(-2);
      let now = moment();
      let exp_date = moment(expiration);

      let diff = Math.ceil(moment.duration(exp_date.diff(now)).asDays());
      if (isNaN(diff) ) { diff = 0; }
      this.model.expiration_days = diff;
    }

    update_expiration() {
      console.log("update expiration to " + this.model.expiration_days + " days");
      if ( this.model.expiration_days == "" ) return;
      let new_expiration = moment().add(this.model.expiration_days, 'days');
      this.model.expiration = {
        year: new_expiration.year(),
        month: new_expiration.month()+1, // month is zero-based
        day: new_expiration.date()
      };
    }

    request_trial() {

        if (+this.model.allowedDays == 0 && this.model.licensetype=='default') {
          console.log("unable to process zero day default request");
          return;
        }


        // Situations under which we don't  submit the request:
        // 1. number of days is negative
        if ( parseInt(this.model.expiration_days) < 0 || +this.model.expiration_days%1!=0) {
          console.log("unable to process negative or non-integer day request");
          return;
        }
        if ( parseInt(this.model.users) <= 0 || +this.model.users%1!=0) {
          console.log("unable to process nonpositive or non-integer users request");
          return;
        }

        // Situations under which a license requires approval:
        //
        // 1. requesteddays > approveddays
        // 2. requestedusers > allowedusers
        // 3. deliverto is not in the channel of requestedby
        this.model.approval_reason = "";
        if ( this.model.expiration_days == "" ) { this.model.expiration_days = this.model.allowedDays; }
        if ( +this.model.expiration_days > +this.model.approveddays ) {
          this.model.approval_reason += "Number of requested days (" + this.model.expiration_days + ") exceeds license limit (" + this.model.approveddays + " days)\n";
        }
        if ( +this.model.expiration_days > +this.model.allowedDays ) {
          this.model.approval_reason += "Number of requested days (" + this.model.expiration_days + ") exceeds trial limit (" + this.model.allowedDays +" days)\n";
        }
        if ( this.model.users == "" ) { this.model.users = this.model.approvedusers; }
        let requested_users = parseInt(this.model.users, 10);
        let approved_users = parseInt(this.model.approvedusers, 10);
        console.log(" >> checking requested users = " + requested_users + ", approved users = " + approved_users);
        if ( requested_users > approved_users ) {
          this.model.approval_reason += "Number of requested users (" + this.model.users + ") exceeds pre-approved number of users (" + this.model.approvedusers + ")\n";
        }
        if ( this.model.validRecipient == 'denied-sameuser' ) {
          this.model.approval_reason += "The end-user is the partner\n";
        }
        if ( this.model.validRecipient == 'denied-sameaccount' ) {
          this.model.approval_reason += "The end-user is in the same company as the partner\n";
        }
        if ( this.model.validRecipient == 'denied-wrongchannel' ) {
          this.model.approval_reason += "End-user (" + this.model.deliverto + ") is not in the channel for " + this.model.username + "\n";
        }
        if ( this.model.approval_reason != "" ) {
          if ( this.model.purpose == undefined || this.model.purpose == "" || this.model.purpose.length < 5 ) {
            console.log("unable to process request with no reason when approval is required");
            return;
          }
        }

        this.loading = true;
        this.model.stage=2;

        console.log("submitting request, approval reason = " + this.model.approval_reason);
        this.portalService.submitTrialRequest(this.model)
        .subscribe(
          data => {
            console.log("submitTrialRequest: %o", data);
            this.model.tlr_name = data.ret;
            if ( this.model.tlr_name == "ERROR" ) {
              this.alertService.error("Your trial request was not processed.  Contact presales@graphon.com for assistance.");
            } else if ( this.model.approval_reason == "" ) {
              this.alertService.success("Your trial request has been processed.");
            } else {
              this.alertService.success("Your trial request has been submitted.");
            }
            this.model.stage=3;
            this.loading=false;
          },
          error => {
            this.loading=false;
            this.alertService.error('Unknown error: ' + error + '. Please try again.');
            this.model.stage=1;
          }
        );
    }
}
