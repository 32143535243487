import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { DomSanitizer } from '@angular/platform-browser';
import { EventBusService, EventData } from 'portal/_services';
var ResourcesComponent = /** @class */ (function () {
    function ResourcesComponent(route, sanitizer, cd, bus) {
        this.route = route;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.bus = bus;
        this.model = {};
        this.model.groups = new Map();
        this.model.indexresources = new Map();
        this.route = route;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log("resources display: current user = %o", this.currentUser);
    }
    ResourcesComponent.prototype.navigated = function () {
        if (this.model.resource) {
            var resource = this.getResource();
            console.log("selected resource %o", resource);
            if (resource._openas == 'NEWTAB') {
                window.open(resource.url, '_blank');
                this.model.resource = undefined;
                this.model.group = undefined;
            }
            else if (resource._openas == 'PAGE') {
                window.open(resource.url);
                this.model.resource = undefined;
                this.model.group = undefined;
            }
            else if (resource._openas == 'INTERNAL') {
                this.model.resource = undefined;
            } // else rendered by *ngIf elements in template iframe
        }
        else {
            if (this.model.group != undefined) {
                this.model.resources = [];
                for (var _i = 0, _a = this.currentUser.resources.resource; _i < _a.length; _i++) {
                    var resource = _a[_i];
                    if (resource.group.__text == this.model.group) {
                        this.model.resources.push({
                            name: resource.name,
                            url: resource.url,
                            sort: resource._sort,
                            group: resource.group.__text,
                            openas: resource._openas
                        });
                    }
                }
                this.model.resources.sort(function (a, b) {
                    var c = parseInt(a.sort, 10);
                    var d = parseInt(b.sort, 10);
                    return c > d ? 1 : c < d ? -1 : 0;
                });
                console.log("resource = %o", this.model.resources);
            }
        }
        this.bus.emit(new EventData('navigate', { group: this.model.group, resource: this.model.resource }));
        console.log("navigated");
        window.scrollTo(0, 0);
    };
    ResourcesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.model.group = params['group'];
            _this.model.resource = params['resource'];
            _this.navigated();
        });
        if (this.model.group == undefined) {
            var params = new URLSearchParams(window.location.search);
            this.model.group = params.paramsMap.get('?group');
            this.model.resource = params.paramsMap.get('?resource');
        }
        if (this.model.group != undefined) {
            this.model.resources = [];
            for (var _i = 0, _a = this.currentUser.resources.resource; _i < _a.length; _i++) {
                var resource = _a[_i];
                if (resource.group.__text == this.model.group) {
                    this.model.resources.push({
                        name: resource.name,
                        url: resource.url,
                        sort: resource._sort,
                        group: resource.group.__text,
                        openas: resource._openas
                    });
                }
            }
            this.model.resources.sort(function (a, b) {
                var c = parseInt(a.sort, 10);
                var d = parseInt(b.sort, 10);
                return c > d ? 1 : c < d ? -1 : 0;
            });
            console.log("resource = %o", this.model.resources);
        }
        this.model.groups.clear();
        if (this.currentUser.resources.resource) {
            for (var _b = 0, _c = this.currentUser.resources.resource; _b < _c.length; _b++) {
                var resource = _c[_b];
                this.model.groups.set(resource.group._sort, resource.group.__text);
            }
        }
        console.log("currentuser = %o\ngrouplist = %o", this.currentUser, this.model.groups);
    };
    ResourcesComponent.prototype.ngAfterViewInit = function () {
        this.cd.detectChanges();
    };
    ResourcesComponent.prototype.getResource = function () {
        for (var _i = 0, _a = this.currentUser.resources.resource; _i < _a.length; _i++) {
            var resource = _a[_i];
            if (resource.name == this.model.resource) {
                return resource;
            }
        }
        return null;
    };
    ResourcesComponent.prototype.getResourceUrl = function () {
        for (var _i = 0, _a = this.currentUser.resources.resource; _i < _a.length; _i++) {
            var resource = _a[_i];
            if (resource.name == this.model.resource) {
                return this.sanitizer.bypassSecurityTrustResourceUrl(resource.url);
            }
        }
        return "";
    };
    ResourcesComponent.prototype.getResourcesForGroup = function (group) {
        var ret = [];
        for (var _i = 0, _a = this.currentUser.resources.resource; _i < _a.length; _i++) {
            var resource = _a[_i];
            if (resource._displayinhomescreen == "true" && resource.group.__text == group) {
                ret.push(resource.name);
            }
        }
        console.log("got resources for group: " + group + ": %o", ret);
        return ret;
    };
    Object.defineProperty(ResourcesComponent.prototype, "diagnostic", {
        get: function () { return JSON.stringify(this.model, null, 2); },
        enumerable: true,
        configurable: true
    });
    ResourcesComponent.prototype.missingIcon = function (event) {
        var errorIconUrl = 'https://cdn.graphon.com/portal_resources/Icons/default.png';
        var target = event.target;
        if (target.src != errorIconUrl) {
            target.src = errorIconUrl;
        }
        else {
            target.style.display = 'none';
        }
    };
    return ResourcesComponent;
}());
export { ResourcesComponent };
