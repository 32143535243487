import { Http, Headers } from '@angular/http';
import { defer } from 'rxjs';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import * as version from "../version";
export function fakeAsyncResponse(data) {
    return defer(function () { return Promise.resolve(data); });
}
var PortalService = /** @class */ (function () {
    function PortalService(http) {
        this.http = http;
        this.gatewayUrl = environment.gatewayUrl + '/go-global/';
        var X2JS = require('node_modules/node-x2js/dist/X2JS.min.js');
        this.x2js = new X2JS();
    }
    PortalService.prototype.activateAccount = function (key) {
        var _this = this;
        return this.http.post(this.gatewayUrl + 'web/activateAccount', "", {
            headers: new Headers({
                "GG_accountActivationKey": key
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return true;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.createPortalUserAccount = function (user) {
        var _this = this;
        return this.http.post(this.gatewayUrl + 'web/createPortalUserAccount', "", {
            headers: new Headers({
                'GG_userFirstName': user.firstname,
                'GG_userLastName': user.lastname,
                'GG_userName': user.emailaddress,
                'GG_userPassword': user.password,
                'GG_ipAddress': user.ipaddress,
                'GG_isCustomer': user.isCustomer,
                'GG_customerInfo': user.customerInfo
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("%o", json);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return true;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.resendActivationLink = function (username) {
        var _this = this;
        return this.http.post(this.gatewayUrl + 'web/resendActivationLink', "", {
            headers: new Headers({
                "GG_userName": username
            })
        }).map(function (response) {
            console.log("resendActivationLink: %o", response);
            var json = _this.x2js.xml_str2json(response.text());
            console.log("resendActivationLink json: %o", json);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return "true";
            }
            else if (json != null && json.rsp._stat == 'fail' && json.rsp.result._exception) {
                return json.rsp.result;
            }
            else {
                return "false";
            }
        });
    };
    PortalService.prototype.queryAccountActivation = function (username) {
        var _this = this;
        return this.http.post(this.gatewayUrl + 'web/queryAccountActivation', "", {
            headers: new Headers({
                "GG_userName": username
            })
        }).map(function (response) {
            console.log("queryAccountActivation: %o", response);
            var json = _this.x2js.xml_str2json(response.text());
            console.log("queryAccountActivation json: %o", json);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                console.log("returning true");
                return "true";
            }
            else if (json != null && json.rsp._stat == 'fail' && json.rsp.result._exception) {
                return json.rsp.result;
            }
            else {
                console.log("returning false");
                return "false";
            }
        });
    };
    PortalService.prototype.getPortalUser = function (email, password) {
        var _this = this;
        return this.http.post(this.gatewayUrl + 'web/getPortalUser', "", {
            headers: new Headers({ 'GG_userName': email, 'GG_userPassword': password })
        }).map(function (response) {
            var _a;
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("%o", json);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                console.log("login successful");
                _this.user = {};
                _this.user.id = json.rsp.user._id;
                _this.user.token = json.rsp.user;
                _this.user.email = email;
                _a = json.rsp.user._name.split(" "), _this.user.firstName = _a[0], _this.user.lastName = _a[1];
                // Gateway returns a list of resources this user has access to
                // no longer returned as part of sign in -- call getGatewayResources() instead
                // this.user.resources = json.rsp.resources;
                console.log("resources configured");
                console.log("%o", _this.user);
                // XxX FIXME - should not be hard coded
                localStorage.setItem('currentUser', JSON.stringify(_this.user));
            }
            else {
                console.log("throwing exception: " + json.rsp.result);
                _this.user = {};
                if (json.rsp.result == "null") {
                    json.rsp.result = "unknown error, please try again";
                }
                throw "Invalid login: " + json.rsp.result;
            }
            return _this.user;
        });
    };
    PortalService.prototype.signin = function (username, password) {
        var _this = this;
        return this.http.post(this.gatewayUrl + 'web/api/signin', "", {
            headers: new Headers({ 'user': username, 'pass': password })
        }).map(function (response) {
            var _a;
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text() + "\n%o", json);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                console.log("login successful");
                _this.user = {};
                _this.user.id = json.rsp.user._id;
                _this.user.token = json.rsp.user;
                _this.user.email = username;
                _a = json.rsp.user._name.split(" "), _this.user.firstname = _a[0], _this.user.lastName = _a[1];
                console.log("user = %o", _this.user);
                localStorage.setItem('currentUser', JSON.stringify(_this.user));
            }
            else {
                console.log("signin exception: " + json.rsp.result);
                _this.user = {};
                if (json.rsp.result == "null") {
                    json.rsp.result = "unknown error, please try again";
                }
                else if (json.rsp.result._exception == "org.springframework.security.LockedException") {
                    if (json.rsp.result._period == "permanent") {
                        throw "Your account has been administratively locked. Please contact sales@graphon.com for assistance.";
                    }
                    throw "Your account has been temporarily locked because an incorrect password was entered too many times.\nYou can try to sign in again in " + json.rsp.result._period + " minutes.";
                }
                throw "Invalid login: " + json.rsp.result;
            }
            return _this.user;
        });
    };
    PortalService.prototype.getGatewayResources = function () {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('currentUser'));
        console.log('parsed user as %o', user);
        return this.http.post(this.gatewayUrl + 'web/api/getGatewayResources', "", {
            headers: new Headers({ 'utok': user.token.__text })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("resource response = " + response.text() + "\n%o", json);
            user.resources = json.resources;
            localStorage.setItem('currentUser', JSON.stringify(user));
            console.log("storing user with resources: %o", user);
        });
    };
    PortalService.prototype.sendPasswordReset = function (email) {
        var _this = this;
        return this.http.post(this.gatewayUrl + 'web/sendPasswordReset', "", {
            headers: new Headers({ 'GG_userName': email })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = %o", response);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return true;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.updateLicenseDescription = function (id, description) {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.post(this.gatewayUrl + 'web/updateLicenseDescription', "", {
            headers: new Headers({ 'GG_userInstanceId': user.token.__text, 'GG_licenseId': id, 'GG_description': description })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = %o", response);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return true;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.updateLicenseUsers = function (id, users) {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.post(this.gatewayUrl + 'web/updateLicenseUsers', "", {
            headers: new Headers({ 'GG_userInstanceId': user.token.__text, 'GG_licenseId': id, 'GG_quantity': users })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("updateLicenseUsers response = %o", response);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return true;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.getHostsUsingLicense = function (id) {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.post(this.gatewayUrl + 'web/getHostsUsingLicense', "", {
            headers: new Headers({ 'GG_userInstanceId': user.token.__text, 'GG_licenseId': id })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("gethostsusinglicense response: %o", response);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result) {
                return json.rsp.hosts;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.changePassword = function (activationKey, password) {
        var _this = this;
        return this.http.post(this.gatewayUrl + 'web/changePassword', "", {
            headers: new Headers({
                'GG_accountActivationKey': activationKey,
                'GG_userPassword': password
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return true;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.logout = function () {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('currentUser'));
        // remove user from local storage to log user out
        console.log("logging out");
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentAccount');
        localStorage.removeItem('currentProduct');
        localStorage.removeItem('currentLicense');
        localStorage.removeItem('currentHost');
        localStorage.removeItem('currentHardware');
        localStorage.removeItem('currentConfig');
        localStorage.removeItem('currentLicenseName');
        localStorage.removeItem('currentMode');
        localStorage.removeItem('exitOnly');
        localStorage.removeItem('existingLicenseName');
        localStorage.removeItem('currentHeading');
        localStorage.removeItem('currentConnection');
        localStorage.removeItem('currentInstruction');
        localStorage.removeItem('currentConnectionPath');
        localStorage.removeItem('currentWarning');
        localStorage.removeItem('currentUUID');
        if (user && user.token && user.token.__text) {
            return this.http.post(this.gatewayUrl + "web/releasePortalUser", "", {
                headers: new Headers({
                    'GG_userInstanceId': user.token.__text
                })
            }).map(function (response) {
                var json = _this.x2js.xml_str2json(response.text());
                console.log("releasePortalUser response: %o", json.rsp);
            });
        }
    };
    PortalService.prototype.getAccounts = function () {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/getAccounts', "", {
            headers: new Headers({
                'GG_userInstanceId': userInstanceID
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                //localStorage.setItem('currentAccount', JSON.stringify(json.rsp.accounts));
                //return true;
                //localStorage.setItem('currentAccount', JSON.stringify(json.rsp.accounts.account));
                return json.rsp.accounts;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.getCloudLicenseAdminLicenses = function () {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.post(this.gatewayUrl + 'web/getCloudLicenseAdminLicenses', "", {
            headers: new Headers({
                'GG_userInstanceId': userInfo.token.__text
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp.licenses;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.deactivateHosts = function (hostIds) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var hostIdString = hostIds.join();
        return this.http.post(this.gatewayUrl + 'web/deactivateHosts', "", {
            headers: new Headers({
                'GG_userInstanceId': userInfo.token.__text,
                'GG_hostId': hostIdString
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("deactivateHosts rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return true;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.getLicenses = function () {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var accountInfo = JSON.parse(localStorage.getItem('currentAccount'));
        var productInfo = JSON.parse(localStorage.getItem('currentProduct'));
        var userInstanceID = undefined;
        var accountID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        if (accountInfo == null) {
            throw "Error: Account not found";
        }
        else {
            accountID = accountInfo._id;
        }
        if (productInfo == null) {
            productInfo = "GO Global - Windows";
        }
        return this.http.post(this.gatewayUrl + 'web/getLicenses', "", {
            headers: new Headers({
                'GG_userInstanceId': userInstanceID,
                'GG_productFamily': productInfo,
                'GG_accountId': accountID,
                'GG_hostVersion': version.version_info.hostVersion
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                /*if (json.rsp.licenses == "") {
                    var trialButton = document.getElementById("startTrialButton");

                    if (trialButton) {
                        trialButton.style.display="block";
                    }
                }*/
                return json.rsp.licenses;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.getTrialLicenseTemplates = function () {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var productInfo = JSON.parse(localStorage.getItem('currentProduct'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        if (productInfo == null) {
            productInfo = "GO Global - Windows";
        }
        return this.http.post(this.gatewayUrl + 'web/getTrialLicenseTemplates', "", {
            headers: new Headers({
                'GG_userInstanceId': userInstanceID,
                'GG_productFamily': productInfo
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp.licenses;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.canActivateLicense = function (licenseid, accountid) {
        var _this = this;
        var uuid = localStorage.getItem('currentUUID');
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/canActivateLicense', "", {
            headers: new Headers({
                'GG_userInstanceId': userInstanceID,
                'GG_licenseId': licenseid,
                'GG_accountId': accountid,
                'GG_computerUuid': uuid,
                'GG_hostVersion': version.version_info.hostVersion
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return [licenseid, json.rsp];
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.getLicenseInfo = function (licenseid) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/getLicenseInfo', "", {
            headers: new Headers({
                'GG_licenseId': licenseid,
                'GG_userInstanceId': userInstanceID
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp.license;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.redeemTrialLicense = function (licenseid, accountid, description) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/redeemTrialLicense', "", {
            headers: new Headers({
                'GG_userInstanceId': userInstanceID,
                'GG_licenseId': licenseid,
                'GG_accountId': accountid,
                'GG_description': description
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                localStorage.setItem('currentLicense', json.rsp.id);
                localStorage.setItem('currentLicenseName', json.rsp.name);
                return json.rsp.name;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.activateProduct = function (licenseid) {
        var _this = this;
        var uuid = localStorage.getItem('currentUUID');
        var operatingSystem = localStorage.getItem('currentOS');
        var computerName = localStorage.getItem('currentHost');
        var macAddress = localStorage.getItem('currentHardware');
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/activateProduct', "", {
            headers: new Headers({
                'GG_userInstanceId': userInstanceID,
                'GG_licenseId': licenseid,
                'GG_computerName': computerName,
                'GG_macAddress': macAddress,
                'GG_computerUuid': uuid,
                'GG_operatingSystem': operatingSystem,
                'GG_hostVersion': version.version_info.hostVersion
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp.item;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.getUserAccountInfo = function () {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw 'Error: not logged in';
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/getUserAccountInfo', "", {
            headers: new Headers({
                'GG_userName': userInfo.email,
                'GG_userInstanceId': userInstanceID,
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                userInfo.accountInfo = json.rsp.user;
                localStorage.setItem('currentUser', JSON.stringify(userInfo));
                return json.rsp;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    // stubbed out methods for demo license generation
    PortalService.prototype.getUserTrialStatus = function () {
        //return fakeAsyncResponse(1);
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/getUserTrialStatus', "", {
            headers: new Headers({
                'GG_userName': userInfo.email,
                'GG_userInstanceId': userInstanceID
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.validateTrialRecipient = function (recipient) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/validateTrialRecipient', "", {
            headers: new Headers({
                'GG_userName': userInfo.email,
                'GG_userInstanceId': userInstanceID,
                'GG_emailAddress': recipient
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.validateHostEligibility = function (hostid, version) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/validateHostEligibility', "", {
            headers: new Headers({
                'GG_userInstanceId': userInstanceID,
                'GG_userName': userInfo.email,
                'GG_hostId': hostid,
                'GG_version': version
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.submitTrialRequest = function (model) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw "Error: not logged in";
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/submitTrialRequest', "", {
            headers: new Headers({
                "GG_version": model.version,
                "GG_licenseType": model.licensetype,
                "GG_deliverTo": model.deliverto,
                "GG_hostname": encodeURIComponent(model.hostname),
                "GG_hostID": encodeURIComponent(model.hostid),
                "GG_description": model.purpose,
                "GG_expiration": model.licensetype == "default" ? "default" : model.expiration.year + '-' + ('0' + model.expiration.month).slice(-2) + '-' + ('0' + model.expiration.day).slice(-2),
                "GG_users": model.users,
                "GG_firstName": model.firstname,
                "GG_lastName": model.lastname,
                "GG_customerInfo": model.endusercompany,
                "GG_country": model.endusercountry,
                'GG_userInstanceId': userInstanceID,
                'GG_userName': userInfo.email,
                'GG_approval': encodeURIComponent(model.approval_reason)
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.validateEmail = function (email) {
        return this.http.get('/register/index.php?op=validateEmail&email=' + encodeURIComponent(email));
    };
    PortalService.prototype.verifyEmail = function (email) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw 'Error: not logged in';
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/verifyEmail', "", {
            headers: new Headers({
                'GG_userName': userInfo.email,
                'GG_userInstanceId': userInstanceID,
                'GG_emailAddress': email
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            console.log("response = " + response.text());
            console.log("rsp = %o", json.rsp);
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp;
            }
            else {
                throw "Error: " + json.rsp.result;
            }
        });
    };
    PortalService.prototype.lookupExistingTrial = function (licenseName, email) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw 'Error: not logged in';
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/lookupExistingTrial', "", {
            headers: new Headers({
                'GG_userName': userInfo.email,
                'GG_userInstanceId': userInstanceID,
                'GG_licenseId': licenseName,
                'GG_emailAddress': email
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp;
            }
            else {
                throw "Error: " + response.text();
            }
        });
    };
    PortalService.prototype.lookupExistingLicense = function (licenseName, email) {
        var _this = this;
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        var userInstanceID = undefined;
        if (userInfo == null) {
            throw 'Error: not logged in';
        }
        else {
            userInstanceID = userInfo.token.__text;
        }
        return this.http.post(this.gatewayUrl + 'web/lookupExistingLicense', "", {
            headers: new Headers({
                'GG_userName': userInfo.email,
                'GG_userInstanceId': userInstanceID,
                'GG_licenseId': licenseName,
                'GG_emailAddress': email
            })
        }).map(function (response) {
            var json = _this.x2js.xml_str2json(response.text());
            if (json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
                return json.rsp;
            }
            else {
                throw "Error: " + response.text();
            }
        });
    };
    return PortalService;
}());
export { PortalService };
