﻿import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { AlertService, PortalService } from '../_services/index';

import { CookieService } from 'ngx-cookie-service';

@Component({
    moduleId: module.id,
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading: Boolean = false;
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private portalService: PortalService,
        private alertService: AlertService,
        private renderer2: Renderer2,
        private cookies: CookieService) {
          let msie = /msie\s|trident\//i.test(window.navigator.userAgent);
          if ( msie ) {
            this.router.navigate(["/unsupported"]);
          }

            this.model.username = "";
            route.params.subscribe((params) => {
                this.model.username = params['username'];
            });
        }

    ngOnInit() {
        // reset login status
        try {
          this.portalService.logout().subscribe(
            error => {
              // If the sign-out fails, it's likely because we've got a stale
              // cookie, so let's clear it now in hopes that our login will
              // succeed.
              console.log("warning: logout returned " + error);
              this.cookies.delete("JSESSIONID", "/go-global/; Secure");
            }
          );
        } catch ( error ) {
          console.log("logout failed");
          console.log(error);
        }
        // if a "sign out" button is present in the DOM because e.g. someone
        // navigated "back" to the sign-in page, remove it.
        if ( document.getElementById('signOut') != undefined ) {
          let div = document.getElementById('sign_in_section');
          while (div.firstChild) {
            div.removeChild(div.firstChild);
          }
        }

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/resources';
        this.returnUrl = decodeURI(this.returnUrl);

        let autofocus = this.renderer2.selectRootElement('[autofocus]');
        autofocus.focus();
    }

    login() {
        this.loading = true;
        this.portalService.signin(this.model.username, this.model.password)
            .subscribe(
                data => {
                    this.portalService.getUserAccountInfo()
                        .subscribe(
                            data => {
                              this.model.accountname = data.user.account.name;

                              this.portalService.getGatewayResources()
                                  .subscribe(
                                      data => {
                                          console.log("got resources during signin");

                                          var signedInAs = document.getElementById("signed_in_as");
                                          if ( signedInAs != null ) {
                                            // it might be removed because we're embedded in an iframe
                                            signedInAs.style.display='block';
                                          }
                                          var username = document.getElementById("signed_in_username");
                                          if ( username ) {
                                            username.innerHTML = this.model.username;
                                            if ( this.model.accountname != 'GO-GLOBAL TRIALS') {
                                              username.innerHTML += '<br/>' + this.model.accountname;
                                            }
                                          }
                                          var signInLink = document.createElement("a");
                                          signInLink.setAttribute('href', environment.gatewayUrl + '/signin');
                                          signInLink.setAttribute('class', 'cmsmasters_button header_button');
                                          signInLink.appendChild(document.createTextNode("SIGN OUT"));
                                          var signInButton = document.createElement("div");
                                          signInButton.setAttribute('id', 'signOut');
                                          signInButton.appendChild(signInLink);
                                          var signInDiv = document.getElementById("sign_in_section");
                                          if ( signInDiv ) {
                                            signInDiv.appendChild(signInButton);
                                          }
                                          this.router.navigate([this.returnUrl == undefined ? "/resources" : this.returnUrl]);
                                      },
                                      error => {
                                        this.loading = false;
                                        if ( error.toString().includes("Bad credentials") ) {
                                          this.alertService.error("Incorrect login -- if you have forgotten your password please click on 'Forgot Your Password'.");
                                        } else {
                                          this.alertService.error('Unknown error: ' + error +". Please login again.");
                                          this.cookies.delete("JSESSIONID", "/go-global/; Secure");
                                        }
                                    }
                                );
                            }
                      );
                },
                error => {
                    this.loading = false;
                    if ( error == null ) {
                        console.log("unknown error logging in");
                        this.alertService.error("Failed to perform the requested operation.");
                        this.cookies.delete("JSESSIONID", "/go-global/; Secure");
                    } else if ( error.toString().includes("User is disabled") ) {
                        this.router.navigate(['/pollActivation'], { queryParams : { 'username' : this.model.username}});
                    } else if ( error.toString().includes("password.not.valid") ) {
                        this.alertService.error('Your password has not been set. Please reset your password before logging in.', true);
                        this.router.navigate(['/forgotPassword'], { queryParams : { 'username' : this.model.username}});
                    } else if ( error.toString().includes("user.not.found") ||
                                error.toString().includes("Bad credentials")) {
                        this.alertService.error('The user name and/or password is incorrect.');
                    } else if ( error.toString().includes("Your account has been temporarily locked") ) {
                        this.alertService.error(error.toString());
                    } else if ( error.toString().includes("403 Forbidden") ) {
                        // This is generally a synchronization error between TomCat and this app where the JSESSIONID cookie
                        // is not valid.  We try to correct this by clearing the cookie and attempting the login again.
                        this.cookies.delete('JSESSIONID', "/go-global/; Secure");
                        console.log("got error: " + error.toString()+", cleared cookie");
                        this.alertService.error("Failed to perform the requested operation.");
                    } else if ( error.toString().includes("Web API access to getPortalUser") ) {
                        console.log("auth error: " + error.toString());
                        this.alertService.error('Failed to sign you in. Try to sign in from a different browser window or tab.');
                    } else {
                        console.log("auth error: " + error.toString());
                        this.alertService.error('Failed to perform the requested operation.');
                        //this.alertService.error('Authentication error: ' + error);
                    }
                });
    }

    forgotPassword() {
        this.loading=true;
        this.router.navigate(['/forgotPassword'], { queryParams: {'username' : this.model.username}});
    }
}
