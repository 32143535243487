var ElectronIPC = /** @class */ (function () {
    function ElectronIPC() {
        this._ipc = void 0;
        if (window.require) {
            try {
                this._ipc = window.require('electron').ipcRenderer;
                console.log("Configured Electron IPC Renderer");
            }
            catch (e) {
                throw e;
            }
        }
        else {
            console.warn("Electron's IPC was not loaded");
        }
    }
    ElectronIPC.prototype.send = function (channel) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var _a;
        console.log("sending message on channel '" + channel + "'");
        if (!this._ipc) {
            console.log("no IPC Renderer configured");
            return;
        }
        (_a = this._ipc).send.apply(_a, [channel].concat(args));
    };
    ElectronIPC.prototype.on = function (channel, listener) {
        console.log("set handler for messages on channel '" + channel + "'");
        if (!this._ipc) {
            console.log("no IPC Renderer configured");
            return;
        }
        this._ipc.on(channel, listener);
    };
    return ElectronIPC;
}());
export { ElectronIPC };
